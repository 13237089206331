import {hasFailed} from "@flowsquad/react-utils-api";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {AssignedRepositoryRO, RepositoryAPI} from "../../../api/api";
import {Toast} from "../../../components/Form/Toast";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";
import {loadRepositories} from "../../../state/RepositoriesState";

interface Props {
    repository: AssignedRepositoryRO | undefined;
}

const useStyles = makeStyles(() => ({
    linkButton: {
        textTransform: "none",
        backgroundColor: "red"
    }
}));

const DeleteRepository: React.FC<Props> = props => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const {repository} = props;

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const onDeleteClicked = useCallback(async () => {
        if (!repository?.id) {
            setError("Repository ID not set. Please wait and try again.");
            return;
        }

        if (!window.confirm("Are you sure you want to delete this repository? All owners of this repository will be able to restore it in their profile settings.")) {
            return;
        }

        setError(undefined);
        setDisabled(true);

        const result = await RepositoryAPI.deleteRepository(repository.id);
        setDisabled(false);

        if (hasFailed(result)) {
            setError("Could not delete repository: " + result.error);
        } else {
            await dispatch(loadRepositories(true));
            history.push("/");
        }
    }, [repository, history, dispatch]);

    return (
        <Section title="Delete repository">

            <SectionDescription>
                You can delete this repository by clicking the button below. You will not be able to upload new builds
                or access the dashboard or existing builds. All repository owners can restore the repository in their
                repository settings.
            </SectionDescription>

            <Toast severity="error" message={error}/>

            <Button
                className={classes.linkButton}
                disableElevation
                color="secondary"
                disabled={disabled}
                variant="contained"
                onClick={onDeleteClicked}
                size="small">
                Delete repository
            </Button>

        </Section>
    );
};

export default DeleteRepository;