import React from "react";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionCode from "../../../components/Layout/PageLayout/SectionCode";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";
import SmallButton from "../../../components/Layout/PageLayout/SmallButton";
import {spaces} from "../../../util/FormatUtils";

interface Props {
    repositoryId: string | undefined;
    apiKey: string | undefined;
}

const UploadInstructions: React.FC<Props> = props => {
    return (
        <Section title="Upload parameters">

            <SectionDescription>
                Execute the following command in your build directory.
            </SectionDescription>

            <SectionCode>
                bash &lt;(curl -s https://bash.flowcov.io) \<br/>
                {spaces(4)}--repository-id <b>{props.repositoryId || "Loading..."}</b> \<br/>
                {spaces(4)}--api-key <b>{props.apiKey || "Loading..."}</b>
            </SectionCode>

            <SmallButton
                openNewTab
                link="https://docs.flowcov.io/getting-started/uploading-reports"
                text="Learn more"/>

        </Section>
    );
};

export default UploadInstructions;