import {default as React} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AddRepositoryScreen from "../../screens/AddRepositoryScreen";
import MainScreen from "../../screens/MainScreen";
import RepositoryScreen from "../../screens/RepositoryScreen";
import UserSettings from "../../screens/UserSettings";

const Router: React.FC = () => {
    return (
        <Switch>

            <Route
                exact
                path="/"
                component={MainScreen} />

            <Route
                exact
                path="/settings"
                component={UserSettings} />

            <Route
                exact
                path="/repository/add"
                component={AddRepositoryScreen} />

            <Route
                path="/repository/:repositoryId"
                component={RepositoryScreen}/>

            <Redirect to="/"/>

        </Switch>
    );
};

export default Router;