import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useState} from "react";
import {AssignedRepositoryRO} from "../../api/api";
import {isAtLeast} from "../../util/RoleUtils";
import DeleteRepository from "./RepositorySettings/DeleteRepository";
import InviteMember from "./RepositorySettings/InviteMember";
import LeaveRepository from "./RepositorySettings/LeaveRepository";
import RepositoryMembers from "./RepositorySettings/RepositoryMembers";
import UploadInstructions from "./RepositorySettings/UploadInstructions";
import UserRole from "./RepositorySettings/UserRole";

interface Props {
    repository?: AssignedRepositoryRO;
    apiKey?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%",
        maxWidth: "960px",
        margin: "1rem auto 0 auto"
    }
}));

const RepositorySettings: React.FC<Props> = props => {
    const classes = useStyles();

    const [reload, setReload] = useState(0);

    return (
        <div className={classes.container}>

            <UserRole repository={props.repository}/>

            {isAtLeast("MEMBER", props.repository) && (

                <UploadInstructions
                    apiKey={props.apiKey}
                    repositoryId={props.repository?.id}/>

            )}

            {isAtLeast("ADMIN", props.repository) && (<>

                <RepositoryMembers
                    key={reload}
                    repository={props.repository}/>

                <InviteMember
                    repository={props.repository}
                    reload={() => setReload(reload => reload + 1)}/>

            </>)}

            <LeaveRepository repository={props.repository}/>

            {isAtLeast("OWNER", props.repository) && (<>

                <DeleteRepository repository={props.repository}/>

            </>)}

        </div>
    );
};

export default RepositorySettings;