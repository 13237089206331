import {Button, CircularProgress, TextField, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Book, FolderOpen, GitHub, History, Person, Refresh} from "@material-ui/icons";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import CoverageCircle from "../components/Coverage/CoverageCircle";
import {Toast} from "../components/Form/Toast";
import Branch from "../components/Icons/Branch";
import Commit from "../components/Icons/Commit";
import {loadRepositories} from "../state/RepositoriesState";
import {RootState} from "../state/Store";
import {compareTimestamps, timeSince} from "../util/DateUtils";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: "2rem auto",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "960px"
    },
    heading: {
        fontSize: "1.25rem",
        color: theme.palette.text.primary,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        margin: "0.5rem auto"
    },
    headingIcon: {
        marginRight: "0.5rem"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "2rem 0px"
    },
    addButton: {
        textTransform: "none",
        fontSize: "1rem"
    },
    loadingWrapper: {
        display: "flex",
        margin: "4rem",
        justifyContent: "center"
    },
    empty: {
        marginTop: "6rem",
        fontSize: "1rem",
        justifyContent: "center",
        display: "flex"
    },
    link: {
        textDecoration: "none",
        color: "#0366d6",
        fontSize: "1rem",
        margin: "0.5rem auto"
    },
    entry: {
        borderTop: "1px solid " + theme.palette.divider,
        marginTop: "1rem",
        padding: "2rem 0rem",
        display: "flex",
        flexDirection: "row"
    },
    title: {
        marginRight: "auto",
        color: "#0366d6",
        textDecoration: "none",
        fontSize: "1.25rem",
        marginBottom: "0.5rem",
        fontWeight: 600
    },
    coverage: {
        flexShrink: 0
    },
    details: {
        maxWidth: "calc(100% - 64px)",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "2rem",
        flexGrow: 1,
        flexShrink: 1
    },
    textLine: {
        marginTop: "0.5rem",
        display: "flex",
        fontWeight: 300
    },
    textLineItem: {
        marginRight: "1.25rem",
        display: "flex",
        alignItems: "center"
    },
    textLineIcon: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.9rem",
        marginRight: "0.4rem"
    },
    textLineIconExplicit: {
        fill: "rgba(0,0,0,0.54)",
        height: "16px",
        marginRight: "0.4rem"
    },
    search: {
        width: "400px"
    },
    url: {
        maxWidth: "250px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    commitMessage: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
}));

const MainScreen: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");

    const repositories = useSelector((state: RootState) => state.repositories);

    useEffect(() => {
        dispatch(loadRepositories());
    }, [dispatch]);

    const filteredRepositories = useMemo(() => {
        return repositories.value?.filter(repository =>
            JSON.stringify([
                repository.name,
                repository.branchName,
                repository.commitMessage,
                repository.commitAuthor,
                repository.url
            ]).toLowerCase().indexOf(search.toLowerCase()) !== -1)
            .sort((a, b) => compareTimestamps(a.modifiedAt, b.modifiedAt))
    }, [repositories, search]);

    return (
        <div className={classes.container}>

            <span className={classes.heading}>
                <Book className={classes.headingIcon}/>
                My Repositories
            </span>

            <Toast
                message={repositories.error && !repositories.loading ? "Could not load data." : undefined}
                action={{
                    onClick: () => dispatch(loadRepositories(true)),
                    icon: Refresh
                }}/>

            {repositories.loading && !repositories.value && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress size={48}/>
                </div>
            )}

            {repositories.value && (
                <>

                    <div className={classes.header}>

                        <TextField
                            size="small"
                            value={search}
                            variant="outlined"
                            label="Find a repository..."
                            className={classes.search}
                            onChange={e => setSearch(e.target.value)}/>

                        <Button
                            color="primary"
                            disableElevation
                            variant="contained"
                            className={classes.addButton}
                            onClick={() => history.push("/repository/add")}>
                            Add Repository
                        </Button>

                    </div>

                    {filteredRepositories?.length === 0 && (
                        <>
                            <span className={classes.empty}>
                                <FolderOpen fontSize="small" className={classes.headingIcon}/>
                                No repositories found.
                            </span>
                            <Link
                                className={classes.link}
                                to="/repository/add">
                                Add a repository.
                            </Link>
                        </>
                    )}

                    {filteredRepositories?.map(repository => {
                        const commitMessage = repository.commitMessage || "No commits found.";
                        const commitAuthor = repository.commitAuthor
                            ? (repository.commitAuthor.indexOf("<") !== -1
                                ? repository.commitAuthor?.substring(0, repository.commitAuthor?.indexOf("<"))
                                : repository.commitAuthor)
                            : "n/a";
                        const branchName = repository.branchName
                            ? (repository.branchName?.length > 32 ? (repository.branchName.substr(0, 29) + "...") : repository.branchName)
                            : "n/a";
                        const commitId = repository.commitId?.substr(0, 7) || "n/a";
                        const commitAuthorFull = repository.commitAuthor || "n/a";
                        const repositoryUrl = repository.url
                            ? (repository.url?.length > 32 ? (repository.url.substr(0, 29) + "...") : repository.url)
                            : "n/a";
                        return (
                            <div
                                key={repository.id}
                                className={classes.entry}>
                                <CoverageCircle
                                    showText
                                    className={classes.coverage}
                                    totalCoverage={repository.coverage}
                                    dmnCoverage={repository.dmnCoverage}
                                    bpmnCoverage={repository.bpmnCoverage}/>
                                <div className={classes.details}>
                                    <Link
                                        to={"/repository/" + repository.id}
                                        className={classes.title}>
                                        {repository.name}
                                    </Link>
                                    <span
                                        title={commitMessage}
                                        className={classes.commitMessage}>
                                    {commitMessage}
                                </span>
                                    <div className={classes.textLine}>
                                    <span className={classes.textLineItem}>
                                        <GitHub className={classes.textLineIcon}/>
                                        <span
                                            title={repository.url}
                                            className={classes.url}>
                                            {repositoryUrl}
                                        </span>
                                    </span>
                                        <span
                                            className={classes.textLineItem}
                                            title={repository.branchName || "n/a"}>
                                        <Branch className={classes.textLineIconExplicit}/>
                                            {branchName}
                                    </span>
                                        <span
                                            className={classes.textLineItem}
                                            title={repository.commitId || "n/a"}>
                                        <Commit className={classes.textLineIconExplicit}/>
                                            {commitId}
                                    </span>
                                        <span
                                            className={classes.textLineItem}
                                            title={commitAuthorFull}>
                                        <Person className={classes.textLineIcon}/>
                                            {commitAuthor}
                                    </span>
                                        <span className={classes.textLineItem}>
                                        <History className={classes.textLineIcon}/>
                                            Updated {timeSince(repository.modifiedAt)} ago
                                    </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </>
            )}

        </div>
    );
};

export default MainScreen;