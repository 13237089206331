import {useAuth0} from "@auth0/auth0-react";
import {Button, CircularProgress} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Settings} from "@material-ui/icons";
import {default as React} from "react";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    progress: {
        color: "rgba(255,255,255,0.54)",
        margin: "auto 2rem"
    },
    userContainer: {
        margin: "auto",
        display: "flex",
        alignItems: "center"
    },
    user: {
        color: "white",
        fontSize: "1rem",
        marginRight: "0.5rem"
    },
    logout: {
        textTransform: "none",
        borderColor: "white",
        color: "white",
        fontSize: "1rem"
    },
    docs: {
        textTransform: "none",
        borderColor: "white",
        color: "white",
        fontSize: "1rem",
        marginRight: "0.5rem"
    },
    login: {
        textTransform: "none",
        borderColor: "white",
        color: "white",
        fontSize: "1rem",
        margin: "auto 2rem"
    },
    settings: {
        padding: 0,
        fontSize: "1.2rem",
        borderColor: "white",
        color: "white",
        margin: "0.5rem",
        minHeight: "40px",
        minWidth: "40px"
    }
}));

const UserButton: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const {loginWithRedirect, logout, isAuthenticated, isLoading, user} = useAuth0();

    if (isLoading) {
        return (
            <CircularProgress
                className={classes.progress}
                size={24}/>
        );
    }

    if (isAuthenticated) {
        return (
            <div className={classes.userContainer}>
                <span className={classes.user}>{user.name}</span>
                <Button
                    variant="outlined"
                    className={classes.settings}
                    onClick={() => history.push("/settings")}>
                    <Settings />
                </Button>
                <Button
                    variant="outlined"
                    className={classes.docs}
                    onClick={() => window.open("https://docs.flowcov.io", "_blank")}>
                    Docs
                </Button>
                <Button
                    variant="outlined"
                    className={classes.logout}
                    onClick={() => logout({returnTo: "https://flowcov.io"})}>
                    Logout
                </Button>
            </div>
        )
    }

    return (
        <Button
            variant="outlined"
            className={classes.login}
            onClick={() => loginWithRedirect()}>
            Login / Sign Up
        </Button>
    );
}

export default UserButton;