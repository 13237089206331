import {Container, Grid, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import github from "../../../img/social/github.svg";
import linkedIn from "../../../img/social/linkedin.svg";
import medium from "../../../img/social/medium.svg";
import twitter from "../../../img/social/twitter.svg";
import xing from "../../../img/social/xing.svg";
import FooterBottomLink from "./FooterBottomLink";
import FooterColumn from "./FooterColumn";
import FooterLine from "./FooterLine";
import SocialIcon from "./SocialIcon";

const useStyles = makeStyles((theme: Theme) => ({
    footerContentTop: {
        background: theme.palette.secondary.light
    },
    footerContentCenter: {
        background: theme.palette.secondary.main
    },
    footerContentBottom: {
        background: theme.palette.secondary.dark
    },
    bottomContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: "1rem"
    },
    footerContent: {
        display: "flex",
        padding: "2rem 0px",
        transition: theme.transitions.create("padding"),
        [theme.breakpoints.only("xs")]: {
            padding: "0px 0px 2rem 0px"
        }
    },
    socialList: {
        padding: "1rem",
        display: "flex",
        justifyContent: "center"
    }
}));

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <footer>

            <div className={classes.footerContentTop}>
                <Container>
                    <div className={classes.socialList}>

                        <SocialIcon
                            title="Twitter"
                            href="https://twitter.com/flowsquad_io"
                            icon={twitter}/>

                        <SocialIcon
                            title="XING"
                            href="https://www.xing.com/companies/flowsquadgmbh"
                            icon={xing}/>

                        <SocialIcon
                            title="LinkedIn"
                            href="https://www.linkedin.com/company/flowsquad"
                            icon={linkedIn}/>

                        <SocialIcon
                            title="Medium"
                            href="https://medium.com/@flowsquad"
                            icon={medium}/>

                        <SocialIcon
                            title="GitHub"
                            href="https://github.com/FlowSquad"
                            icon={github}/>

                    </div>
                </Container>
            </div>

            <div className={classes.footerContentCenter}>
                <Container>
                    <Grid
                        justify="center"
                        container
                        className={classes.footerContent}>

                        <FooterColumn title="FlowCov.io">

                            <FooterLine
                                external
                                title="Features"
                                href="https://flowcov.io"/>

                            <FooterLine
                                external
                                title="Documentation"
                                href="https://docs.flowcov.io"/>

                            <FooterLine
                                external
                                title="Docs: FAQ"
                                href="https://docs.flowcov.io/faq"/>

                            <FooterLine
                                title="App"
                                href="/"/>

                        </FooterColumn>

                        <FooterColumn title="FlowSquad.io">

                            <FooterLine
                                external
                                title="About Us"
                                href="https://flowsquad.io"/>

                            <FooterLine
                                external
                                title="BPM"
                                href="https://flowsquad.io/bpm"/>

                            <FooterLine
                                external
                                title="FlowSquad Academy"
                                href="https://flowsquad.io/academy"/>

                            <FooterLine
                                external
                                title="Software Development"
                                href="https://flowsquad.io/softwareentwicklung"/>

                        </FooterColumn>

                        <FooterColumn title="Company">

                            <FooterLine title="info@flowsquad.io"/>
                            <FooterLine title="FlowSquad GmbH"/>
                            <FooterLine title="Salomon-Idler-Straße 30"/>
                            <FooterLine title="86159 Augsburg"/>
                            <FooterLine title="Germany"/>

                        </FooterColumn>
                    </Grid>
                </Container>
            </div>
            <div className={classes.footerContentBottom}>
                <Container className={classes.bottomContainer}>

                    <FooterBottomLink
                        href="https://flowsquad.io/impressum"
                        title="Imprint"/>

                    <FooterBottomLink
                        href="https://flowcov.io/privacy"
                        title="Privacy Policy"/>
                </Container>

            </div>

        </footer>
    );
};

export default Footer;
