import {makeStyles, Theme} from "@material-ui/core/styles";
import {default as React} from "react";

const useStyles = makeStyles((theme: Theme) => ({
    beta: {
        border: "1px solid white",
        borderRadius: "4px",
        color: "white",
        margin: "auto 0rem auto 1rem",
        padding: "0.25rem 0.5rem",
        fontSize: "0.8rem",
        fontWeight: 600
    }
}));

const BetaBadge: React.FC = () => {
    const classes = useStyles();

    return (
        <span className={classes.beta}>
            BETA
        </span>
    );
}

export default BetaBadge;