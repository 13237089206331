import {hasFailed} from "@flowsquad/react-utils-api";
import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Refresh} from "@material-ui/icons";
import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SunburstChart from "sunburst-chart";
import {AssignedRepositoryRO, BuildAPI, BuildPreviewRO, BuildRO} from "../../api/api";
import BuildRow from "../../components/Builds/BuildRow";
import {Toast} from "../../components/Form/Toast";
import {compareTimestamps} from "../../util/DateUtils";
import RepositoryInstructions from "./RepositoryInstructions";

interface Props {
    repository?: AssignedRepositoryRO;
    apiKey?: string;
    builds?: BuildPreviewRO[];
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    },
    sectionContainer: {
        marginTop: "2rem",
        border: "2px solid rgba(34, 36, 38, 0.1)",
        backgroundColor: "rgba(255, 255, 255, 0.54)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column"
    },
    sectionTitle: {
        height: "36px",
        padding: "0.5rem",
        backgroundColor: "rgba(34, 36, 38, 0.1)",
        display: "block",
        width: "100%",
        fontWeight: 500
    },
    sectionHint: {
        textAlign: "center",
        color: theme.palette.text.hint
    },
    sunburstWrapper: {
        margin: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    sunburst: {
        margin: "2rem 0"
    },
    currentHovered: {
        display: "block",
        minHeight: "4rem"
    },
    hoveredPart: {
        whiteSpace: "nowrap",
        maxWidth: "600px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block"
    },
    link: {
        textDecoration: "none"
    },
    linkButton: {
        textTransform: "none"
    },
    sectionButtons: {
        padding: "0.5rem"
    },
    error: {
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    }
}));

const coverageToColor = (coverage: number): string => {
    if (coverage >= 0.75) {
        return "green";
    }
    if (coverage >= 0.5) {
        return "orange";
    }
    return "red";
};

const formatCoverage = (coverage: number): string => {
    return " (" + (coverage * 100).toFixed(2) + "% Coverage)";
};

const RepositoryDashboard: React.FC<Props> = props => {
    const classes = useStyles();

    const lastBuildId = props.repository?.lastBuildId;

    const [hovered, setHovered] = useState("");
    const [retry, setRetry] = useState(0);
    const [retryAvailable, setRetryAvailable] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [build, setBuild] = useState<BuildRO | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (lastBuildId) {
                const result = await BuildAPI.loadBuild(lastBuildId);
                if (hasFailed(result)) {
                    if (result.status === 404) {
                        setError("Build not found.");
                        setRetryAvailable(false);
                    } else {
                        setError(result.error);
                        setRetryAvailable(true);
                    }
                } else {
                    setBuild(result.result);
                }
            }
        })();
    }, [lastBuildId, retry]);

    const renderDiagram = useCallback((node: HTMLElement | null) => {
        if (node && build) {
            node.childNodes.forEach(child => node.removeChild(child));
            SunburstChart()
                .width(400)
                .height(400)
                .color("color")
                .showLabels(false)
                .centerRadius(0)
                .showTooltip(() => false)
                .onHover((data) => setHovered(data?.name || ""))
                .onClick(() => {
                })
                .data({
                    name: "Total Build" + formatCoverage(build.averageCoverage),
                    value: build.totalRuleCount + build.totalNodeCount,
                    color: coverageToColor(build.averageCoverage),
                    children: [
                        {
                            name: " All BPMN Models" + formatCoverage(build.bpmnCoverage),
                            // @ts-ignore
                            color: coverageToColor(build.bpmnCoverage),
                            value: build.totalNodeCount * 10000,
                            children: build.bpmnModels.map(model => ({
                                name: "Model " + model.processDefinitionKey + formatCoverage(model.coverage),
                                color: coverageToColor(model.coverage),
                                value: model.totalNodeCount * 100000000
                            }))
                        },
                        {
                            name: "All DMN Decisions" + formatCoverage(build.dmnCoverage),
                            // @ts-ignore
                            color: coverageToColor(build.dmnCoverage),
                            value: build.totalRuleCount * 10000,
                            children: build.dmnModels.map(model => ({
                                name: "Decision " + model.decisionKey + formatCoverage(model.coverage),
                                color: coverageToColor(model.coverage),
                                value: model.totalRuleCount * 100000000
                            }))
                        }
                    ]
                })
                (node);
        }
    }, [build]);

    const latestBuild = props.builds?.find(build => build.id === props.repository?.lastBuildId);

    return (
        <div className={classes.container}>
            <Toast
                className={classes.error}
                message={error}
                action={!retryAvailable ? undefined : {
                    icon: Refresh,
                    onClick: () => setRetry(retry => retry + 1)
                }}/>
            {(props.builds !== undefined && props.builds.length === 0) && (
                <RepositoryInstructions
                    repository={props.repository}
                    apiKey={props.apiKey}/>
            )}
            {(props.builds?.length || 0) > 0 && (
                <>
                    <div className={classes.sectionContainer}>

                        <span className={classes.sectionTitle}>Recent Builds</span>

                        {props.builds
                            ?.sort((a, b) => compareTimestamps(a.createdAt, b.createdAt))
                            .slice(0, Math.min(props.builds.length, 3)).map(build => (
                                <BuildRow
                                    key={build.id}
                                    build={build}
                                    repository={props.repository}/>
                            ))}

                        <div className={classes.sectionButtons}>
                            <Link
                                className={classes.link}
                                to={"/repository/" + props.repository?.id + "/builds"}>
                                <Button
                                    className={classes.linkButton}
                                    disableElevation
                                    color="primary"
                                    variant="contained"
                                    size="small">
                                    Show more
                                </Button>
                            </Link>
                        </div>

                    </div>

                    <div className={classes.sectionContainer}>
                        <span className={classes.sectionTitle}>Latest Coverage Sunburst</span>

                        <div className={classes.sunburstWrapper}>
                            <span className={classes.sectionHint}>Hover image for details.</span>
                            <div className={classes.sunburst} ref={renderDiagram}/>
                            <span className={classes.currentHovered}>
                                <span className={classes.hoveredPart}>{hovered}</span>
                            </span>
                        </div>

                        {latestBuild && (
                            <BuildRow
                                build={latestBuild}
                                repository={props.repository}/>
                        )}
                    </div>

                </>
            )}
        </div>
    );
};

export default RepositoryDashboard;