import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

interface Props {
    title: string;
    href: string;
    icon: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    socialIcon: {
        height: "3rem",
        width: "3rem",
        borderRadius: "50%",
        margin: "0.5rem",
        transition: theme.transitions.create(["opacity", "width", "height"]),
        opacity: 0.75,
        "&:hover": {
            opacity: 1
        },
        [theme.breakpoints.only("xs")]: {
            height: "2.5rem",
            width: "2.5rem"
        }
    },
    socialIconWrapper: {
        display: "flex"
    }
}));

const SocialIcon: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <a
            className={classes.socialIconWrapper}
            title={props.title}
            target="_blank"
            rel="noopener noreferrer"
            href={props.href}>
            <img
                className={classes.socialIcon}
                src={props.icon}
                alt={props.title}
            />
        </a>
    );
};

export default SocialIcon;
