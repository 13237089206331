const compareTimestamps = (dateA: string, dateB: string) => {
    return new Date(dateB).getTime() - new Date(dateA).getTime();
};

const timeSince = (date: string) => {
    const parsed = new Date(date);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - parsed.getTime()) / 1000);

    let interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " years";
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }

    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export {
    timeSince,
    compareTimestamps
};