import {Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

interface Props {
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    contentWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    contentHeadline: {
        color: "white",
        fontSize: "1.2rem",
        marginBottom: "0.25rem",
        fontWeight: 500,
        fontFamily: "neo-sans, sans-serif",
        transition: theme.transitions.create(["text-align", "margin"]),
        [theme.breakpoints.only("xs")]: {
            textAlign: "center",
            marginTop: "2rem"
        }
    },
    contentBlock: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.only("xs")]: {
            width: "100%"
        }
    }
}));

const FooterColumn: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={4} md={3}
              className={classes.contentWrapper}>

            <div className={classes.contentBlock}>

                <Typography className={classes.contentHeadline}>
                    {props.title}
                </Typography>

                {props.children}

            </div>

        </Grid>
    );
};

export default FooterColumn;
