import {IconButton, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Code, Description, PlayArrow, ViewList} from "@material-ui/icons";
import clsx from "clsx";
import React, {ElementType} from "react";
import {
    BpmnModelRO,
    BpmnTestClassRO,
    BpmnTestMethodRO,
    DmnModelRO,
    DmnTestClassRO,
    DmnTestMethodRO
} from "../../api/api";

declare type SummaryRowType = "bpmnModel" | "bpmnClass" | "bpmnMethod" | "dmnModel" | "dmnClass" | "dmnMethod";
declare type SummaryRowModel = BpmnModelRO | BpmnTestClassRO | BpmnTestMethodRO | DmnModelRO | DmnTestClassRO | DmnTestMethodRO;

const mapToData = (type: SummaryRowType, model: SummaryRowModel): RowData => {
    switch (type) {
        case "bpmnModel": {
            const raw = model as BpmnModelRO;
            return {
                colored: true,
                coverage: raw.coverage,
                covered: raw.coveredFlowNodeCount + raw.coveredSequenceFlowCount,
                icon: Code,
                name: raw.processDefinitionKey,
                total: raw.totalNodeCount
            };
        }
        case "bpmnClass": {
            const raw = model as BpmnTestClassRO;
            return {
                colored: false,
                coverage: raw.coverage,
                covered: raw.coveredFlowNodeCount + raw.coveredSequenceFlowCount,
                icon: Description,
                name: raw.name,
                total: raw.totalNodeCount
            };
        }
        case "bpmnMethod": {
            const raw = model as BpmnTestMethodRO;
            return {
                colored: false,
                coverage: raw.coverage,
                covered: raw.coveredFlowNodeCount + raw.coveredSequenceFlowCount,
                icon: Code,
                name: raw.name,
                total: raw.totalNodeCount
            };
        }
        case "dmnModel": {
            const raw = model as DmnModelRO;
            return {
                colored: true,
                coverage: raw.coverage,
                covered: raw.coveredRuleCount,
                icon: ViewList,
                name: raw.decisionKey,
                total: raw.totalRuleCount
            };
        }
        case "dmnClass": {
            const raw = model as DmnTestClassRO;
            return {
                colored: false,
                coverage: raw.coverage,
                covered: raw.coveredRuleCount,
                icon: Description,
                name: raw.name,
                total: raw.totalRuleCount
            };
        }
        case "dmnMethod": {
            const raw = model as DmnTestMethodRO;
            return {
                colored: false,
                coverage: raw.coverage,
                covered: raw.coveredRuleCount,
                icon: Code,
                name: raw.name,
                total: raw.totalRuleCount
            };
        }
    }
}

interface Props {
    type: SummaryRowType;
    model: SummaryRowModel;
    selectedModel?: BpmnModelRO | DmnModelRO;
    selectedClass?: BpmnTestClassRO | DmnTestClassRO;
    selectedMethod?: BpmnTestMethodRO | DmnTestMethodRO;
    onPlayClicked?: () => void;
    onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    summaryRow: {
        cursor: "pointer",
        transition: theme.transitions.create("background-color"),
        "&:hover": {
            backgroundColor: "rgba(34, 36, 38, 0.1)"
        },
        "&>td": {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem"
        },
        "&>td:first-child": {
            paddingLeft: "1rem"
        }
    },
    summaryRowActive: {
        backgroundColor: "rgba(34, 36, 38, 0.15)",
        "&>td": {
            fontWeight: 500
        },
        "&:hover": {
            backgroundColor: "rgba(34, 36, 38, 0.15)"
        }
    },
    summaryColumnName: {
        textAlign: "left",
        display: "flex"
    },
    summaryColumnCount: {
        textAlign: "right"
    },
    summaryColumnCoverage: {
        textAlign: "right",
        width: "1%",
        paddingLeft: "1rem"
    },
    summaryColumnButton: {
        width: "3rem",
        textAlign: "center"
    },
    separator: {
        margin: "0 0.25rem"
    },
    rowIcon: {
        marginRight: "0.5rem"
    },
    spacingRow: {
        "&>td": {
            padding: "0.25rem"
        }
    },
    summaryColumnNamePadding1: {
        paddingLeft: "2.75rem !important"
    },
    summaryColumnNamePadding2: {
        paddingLeft: "4.5rem !important"
    },
    coverageGreen: {
        backgroundColor: "rgba(0, 255, 0, 0.38)"
    },
    coverageYellow: {
        backgroundColor: "rgba(255, 255, 0, 0.38)"
    },
    coverageRed: {
        backgroundColor: "rgba(255, 0, 0, 0.38)"
    },
    spacingColumn: {
        width: "3rem"
    },
    empty: {
        padding: "1rem",
        textAlign: "center",
        fontWeight: "bold"
    },
    playIcon: {
        color: theme.palette.primary.main
    }
}));

interface RowData {
    icon: ElementType;
    name: string;
    covered: number;
    total: number;
    coverage: number;
    colored: boolean;
}

const BuildSummaryRow: React.FC<Props> = props => {
    const classes = useStyles();

    const data = mapToData(props.type, props.model);

    const selectedModel = (props.selectedModel as BpmnModelRO)?.processDefinitionKey || (props.selectedModel as DmnModelRO)?.decisionKey;
    const selectedClass = (props.selectedClass as BpmnTestClassRO)?.name || (props.selectedClass as DmnTestClassRO)?.name;
    const selectedMethod = (props.selectedMethod as BpmnTestMethodRO)?.name || (props.selectedMethod as DmnTestMethodRO)?.name;

    return (
        <tr
            onClick={props.onClick}
            className={clsx(
                classes.summaryRow,
                (props.type === "dmnModel" || props.type === "bpmnModel") && selectedModel === data.name && classes.summaryRowActive,
                (props.type === "dmnClass" || props.type === "bpmnClass") && selectedClass === data.name && classes.summaryRowActive,
                (props.type === "dmnMethod" || props.type === "bpmnMethod") && selectedMethod === data.name && classes.summaryRowActive
            )}>
            <td className={clsx(
                classes.summaryColumnName,
                (props.type === "dmnClass" || props.type === "bpmnClass") && classes.summaryColumnNamePadding1,
                (props.type === "dmnMethod" || props.type === "bpmnMethod") && classes.summaryColumnNamePadding2
            )}>
                {React.createElement(data.icon, {
                    className: classes.rowIcon,
                    fontSize: "small"
                })}
                {data.name}
            </td>
            <td className={classes.summaryColumnCount}>{data.covered}</td>
            <td className={classes.summaryColumnCount}>{data.total}</td>
            <td className={classes.spacingColumn}/>
            <td className={clsx(classes.summaryColumnCoverage, {
                [classes.coverageGreen]: data.colored && data.coverage >= 0.9,
                [classes.coverageYellow]: data.colored && data.coverage < 0.9 && data.coverage >= 0.5,
                [classes.coverageRed]: data.colored && data.coverage < 0.5
            })}>
                {(data.coverage * 100).toFixed(2)}%
            </td>
            <td className={classes.summaryColumnButton}>
                {props.type === "bpmnMethod" && (
                    <IconButton
                        size="small"
                        onClick={props.onPlayClicked}>
                        <PlayArrow
                            className={classes.playIcon}
                            fontSize="small" />
                    </IconButton>
                )}
            </td>
        </tr>
    );
};

export default BuildSummaryRow;