import {useAuth0} from "@auth0/auth0-react";
import {setGlobalRequestConfig} from "@flowsquad/react-utils-api";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {default as React, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/Store";
import {loadUserInfo} from "../../state/UserInfoState";
import {retryAction, Toast} from "../Form/Toast";
import Footer from "./Footer/Footer";
import Menu from "./Menu/Menu";
import Router from "./Router";

const useStyles = makeStyles(() => ({
    container: {
        flexGrow: 1
    },
    content: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column"
    },
    errorContainer: {
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        paddingBottom: "50vh"
    },
    errorWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#9f2121",
        margin: "auto",
        padding: "1rem 4rem",
        borderRadius: "1rem",
        color: "white"
    },
    errorMessage: {
        marginTop: "2rem",
        fontSize: "1rem"
    },
    errorRetry: {
        marginTop: "2rem",
        marginBottom: "1rem",
        borderColor: "rgba(255,255,255,0.87)",
        color: "rgba(255,255,255,0.87)",
        textTransform: "none",
        fontSize: "1rem"
    }
}));

const Layout: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const userInfoState = useSelector((state: RootState) => state.userInfo);
    const [initializing, setInitializing] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const {loginWithRedirect, isAuthenticated, isLoading, error, getAccessTokenSilently} = useAuth0();

    if (isLoading) {
        return null;
    }

    if (error) {
        return (
            <div className={classes.errorContainer}>
                <div className={classes.errorWrapper}>
                    <span className={classes.errorMessage}>{error.message}</span>
                    <Button
                        variant="outlined"
                        onClick={loginWithRedirect}
                        className={classes.errorRetry}>
                        Retry Login
                    </Button>
                </div>
            </div>
        );
    }

    if (!isAuthenticated) {
        // noinspection JSIgnoredPromiseFromCall
        loginWithRedirect();
        return null;
    }

    if (!initialized) {
        if (!initializing) {
            setInitializing(true);
            (async () => {
                const token = await getAccessTokenSilently();
                setGlobalRequestConfig({
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                });
                dispatch(loadUserInfo());
                setInitialized(true);
            })();
        }
        return null;
    }

    return (
        <>

            <Menu/>

            <div className={classes.container}>

                <div className={classes.content}>

                    <Toast
                        message={userInfoState.error}
                        action={retryAction(() => dispatch(loadUserInfo()))}/>

                    <Router/>

                </div>

            </div>

            <Footer/>

        </>
    );
}

export default Layout;