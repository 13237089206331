import {hasFailed} from "@flowsquad/react-utils-api";
import {CircularProgress, IconButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Undo} from "@material-ui/icons";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AssignedRepositoryRO, RepositoryAPI} from "../../api/api";
import {retryAction, Toast} from "../../components/Form/Toast";
import Section from "../../components/Layout/PageLayout/Section";
import SectionDescription from "../../components/Layout/PageLayout/SectionDescription";
import {loadRepositories} from "../../state/RepositoriesState";

const useStyles = makeStyles(() => ({
    restoreRow: {
        display: "flex",
        padding: "0.5rem 1rem",
        border: "1px solid #CCC",
        borderRadius: "4px",
        margin: "0.5rem 1rem"
    },
    description: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between"
    },
    title: {
        fontSize: "1rem",
        fontWeight: "bold"
    },
    url: {
        fontSize: "0.9rem",
        fontStyle: "italic"
    }
}));

const RestoreDeletedRepositories: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [repositories, setRepositories] = useState<AssignedRepositoryRO[] | undefined>(undefined);

    const loadDeletedRepositories = useCallback(async () => {
        const response = await RepositoryAPI.loadDeletedRepositories();
        if (hasFailed(response)) {
            setError(response.error);
        } else {
            setRepositories(response.result);
        }
    }, []);

    useEffect(() => {
        loadDeletedRepositories();
    }, [loadDeletedRepositories])

    const onRestoreClicked = useCallback(async (repositoryId: string) => {
        if (!window.confirm("Are you sure you want to restore this repository?")) {
            return;
        }

        setError(undefined);
        setDisabled(true);

        const result = await RepositoryAPI.restoreRepository(repositoryId);
        setDisabled(false);

        if (hasFailed(result)) {
            setError("Could not restore repository: " + result.error);
        } else {
            loadDeletedRepositories();
            await dispatch(loadRepositories(true));
        }
    }, [dispatch, loadDeletedRepositories]);

    return (
        <Section title="Restore deleted repositories">

            <Toast
                severity="error"
                message={error}
                action={retryAction(loadDeletedRepositories)}/>

            <SectionDescription>
                The list below displays all repositories that were deleted while you were its owner. You can restore
                them by clicking the Undo button in each row.
            </SectionDescription>

            {!repositories && (
                <CircularProgress variant="indeterminate"/>
            )}

            {repositories?.length === 0 && (
                <div className={classes.restoreRow}>
                    <span className={classes.title}>There are currently no deleted repositories available to restore.</span>
                </div>
            )}

            {repositories?.map(repository => (
                <div
                    key={repository.id}
                    className={classes.restoreRow}>

                    <div className={classes.description}>
                        <span className={classes.title}>{repository.name}</span>
                        <span className={classes.url}>{repository.url}</span>
                    </div>
                    <IconButton
                        disabled={disabled}
                        onClick={() => onRestoreClicked(repository.id)}>
                        <Undo/>
                    </IconButton>
                </div>
            ))}

        </Section>
    );
};

export default RestoreDeletedRepositories;