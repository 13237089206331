import {hasFailed} from "@flowsquad/react-utils-api";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useState} from "react";
import {AssignedRepositoryRO, RepositoryAPI} from "../../../api/api";
import {Toast} from "../../../components/Form/Toast";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";

interface Props {
    repository: AssignedRepositoryRO | undefined;
    reload: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    inviteForm: {
        display: "flex",
        marginTop: "1rem",
    },
    inviteEmail: {
        flexGrow: 2,
        marginRight: "0.5rem",
        maxWidth: "480px"
    },
    select: {
        flexGrow: 1,
        marginRight: "0.5rem"
    },
    selectPaper: {
        backgroundColor: theme.palette.background.default
    },
    linkButton: {
        textTransform: "none"
    }
}));

const InviteMember: React.FC<Props> = props => {
    const classes = useStyles();

    const {repository, reload} = props;

    const [disabled, setDisabled] = useState(false);
    const [result, setResult] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [inviteEmail, setInviteEmail] = useState("");
    const [inviteRole, setInviteRole] = useState<"OWNER" | "ADMIN" | "MEMBER" | "VIEWER">("MEMBER");

    const isOwner = props.repository?.role === "OWNER";

    const onInviteUserClicked = useCallback(async () => {
        if (!repository?.id) {
            setError("Repository ID not set. Please wait and try again.");
            return;
        }

        setError(undefined);
        setResult(undefined);
        setDisabled(true);

        const result = await RepositoryAPI.inviteUser({
            repositoryId: repository.id,
            role: inviteRole,
            email: inviteEmail.trim()
        });

        setDisabled(false);

        if (hasFailed(result)) {
            if (result.status === 404) {
                setError("User not found.")
            } else {
                setError("Could not invite user: " + result.error);
            }
        } else {
            setResult("User was successfully invited!");
            setInviteEmail("");
            reload();
        }
    }, [repository, inviteEmail, inviteRole, reload]);

    return (
        <Section title="Invite member">

            <SectionDescription>
                Enter an user's email address to invite them to this repository. They will be granted the
                member role and the right to upload and view coverage reports.
            </SectionDescription>

            <Toast severity="error" message={error}/>
            <Toast severity="success" message={result}/>

            <div className={classes.inviteForm}>

                <TextField
                    disabled={disabled}
                    value={inviteEmail}
                    onChange={e => setInviteEmail(e.target.value)}
                    variant="outlined"
                    className={classes.inviteEmail}
                    size="small"
                    label="Email address"/>

                <FormControl
                    disabled={disabled}
                    className={classes.select}
                    size="small"
                    variant="outlined">
                    <InputLabel id="role-label">User Role</InputLabel>
                    <Select
                        MenuProps={{
                            classes: {
                                paper: classes.selectPaper
                            }
                        }}
                        labelId="role-label"
                        label="User Role"
                        variant="outlined"
                        onChange={(e => setInviteRole(e.target.value as "OWNER" | "ADMIN" | "MEMBER" | "VIEWER"))}
                        value={inviteRole}>
                        {isOwner && (
                            <MenuItem value="OWNER">Owner</MenuItem>
                        )}
                        <MenuItem value="ADMIN">Admin</MenuItem>
                        <MenuItem value="MEMBER">Member</MenuItem>
                        <MenuItem value="VIEWER">Viewer</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    className={classes.linkButton}
                    disableElevation
                    color="primary"
                    disabled={disabled || inviteEmail.length < 6}
                    variant="contained"
                    onClick={onInviteUserClicked}
                    size="small">
                    Invite user
                </Button>

            </div>
        </Section>
    );
};

export default InviteMember;