import {Auth0Provider} from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import App from "./components/Layout/App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {store} from "./state/Store";

ReactDOM.render((
    <Provider store={store}>
        <Auth0Provider
            domain="flowsquad.eu.auth0.com"
            clientId="3h8eEHlAfoMF4yYkTOzsDcQc6rgpLOxO"
            audience="https://flowsquad.io"
            authorizeTimeoutInSeconds={5}
            useRefreshTokens
            redirectUri={window.location.origin}>
            <HashRouter>
                <App/>
            </HashRouter>
        </Auth0Provider>
    </Provider>
), document.getElementById("root"));

// If you want your react-ui-common to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
