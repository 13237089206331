import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {repositoriesSlice} from "./RepositoriesState";
import {userInfoSlice} from "./UserInfoState";

const rootReducer = combineReducers({
    repositories: repositoriesSlice.reducer,
    userInfo: userInfoSlice.reducer
});

export const ClearStore = {
    type: "Root:Clear"
};

export const store = configureStore({
    // @ts-ignore TODO
    reducer: (state: RootState, action: { type: any }) => {
        if (action === ClearStore) {
            return rootReducer(undefined, action);
        } else {
            return rootReducer(state, action);
        }
    }
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootDispatch = typeof store.dispatch;