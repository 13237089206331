import {hasFailed} from "@flowsquad/react-utils-api";
import {Button, TextField, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Book} from "@material-ui/icons";
import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {RepositoryAPI} from "../api/api";
import {Toast} from "../components/Form/Toast";
import {loadRepositories} from "../state/RepositoriesState";

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: "2rem 0rem",
        display: "flex",
        flexDirection: "column"
    },
    heading: {
        fontSize: "1.25rem",
        color: theme.palette.text.primary,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        margin: "0.5rem auto"
    },
    headingIcon: {
        marginRight: "0.5rem"
    },
    description: {
        marginTop: "1rem",
        textAlign: "center"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "2rem"
    },
    formField: {
        width: "400px",
        margin: "1rem auto 0rem auto"
    },
    addButton: {
        textTransform: "none",
        fontSize: "1rem",
        width: "400px",
        margin: "1rem auto 0rem auto"
    },
    toast: {
        width: "400px",
        margin: "0 auto"
    }
}));

const AddRepositoryScreen: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);

    const onAddClicked = useCallback(async () => {
        if(name.length < 4) {
            setError("Please choose a longer name.");
            return;
        }

        if(!URL_REGEX.test(url)) {
            setError("Please enter a valid repository url.");
            return;
        }

        setDisabled(true);
        const result = await RepositoryAPI.createRepository({
            url: url,
            name: name
        });

        setDisabled(false);
        if(hasFailed(result)) {
            setError("Could not add repository: " + result.error);
        } else {
            await dispatch(loadRepositories(true));
            history.push("/repository/" + result.result);
        }
    }, [name, url, history, dispatch]);

    return (
        <div className={classes.container}>

            <span className={classes.heading}>
                <Book className={classes.headingIcon}/>
                Add Repository
            </span>

            <span className={classes.description}>
                Enter the name and the URL of the repository you want to add.
            </span>

            <div className={classes.form}>

                <Toast
                    className={classes.toast}
                    message={error} />

                <TextField
                    size="small"
                    value={name}
                    variant="outlined"
                    disabled={disabled}
                    label="Name"
                    className={classes.formField}
                    onChange={e => setName(e.target.value)}/>

                <TextField
                    label="URL"
                    value={url}
                    size="small"
                    variant="outlined"
                    disabled={disabled}
                    className={classes.formField}
                    onChange={e => setUrl(e.target.value)}/>

                <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    disabled={disabled}
                    className={classes.addButton}
                    onClick={onAddClicked}>
                    Add Repository
                </Button>

            </div>

        </div>
    );
};

export default AddRepositoryScreen;