import {Checkbox, FormControlLabel, IconButton, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CenterFocusStrong, CloudDownload, ZoomIn, ZoomOut} from "@material-ui/icons";
import clsx from "clsx";
import React, {useCallback, useEffect, useState} from "react";
import {
    BpmnModelRO,
    BpmnTestClassRO,
    BpmnTestMethodRO, BuildRO,
    DmnModelRO,
    DmnTestClassRO,
    DmnTestMethodRO
} from "../../api/api";
import {downloadFile} from "../../util/FileUtils";
import CoveragePlayer from "../Player/CoveragePlayer";
import BpmnViewer, {BpmnViewerData, BpmnViewerListener} from "./BpmnViewer";
import BuildSummary from "./BuildSummary";
import DmnViewer, {DmnViewerData} from "./DmnViewer";

interface Props {
    build?: BuildRO;
}

const useStyles = makeStyles((theme: Theme) => ({
    settings: {
        zoom: 0.85,
        padding: "0.5rem 0.5rem 0rem 1rem",
        display: "flex"
    },
    settingsLabel: {
        transition: theme.transitions.create("opacity"),
        opacity: 0.38,
        "&:hover": {
            opacity: 0.87
        }
    },
    sectionContainer: {
        border: "2px solid rgba(34, 36, 38, 0.1)",
        backgroundColor: "rgba(255, 255, 255, 0.54)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        maxWidth: "960px",
        width: "100%",
        margin: "2rem auto 0rem auto"
    },
    sectionContainerLarge: {
        margin: "2rem 4rem 0rem 4rem",
        maxWidth: "unset",
        width: "auto"
    },
    sectionTitle: {
        height: "36px",
        padding: "0.5rem",
        backgroundColor: "rgba(34, 36, 38, 0.1)",
        display: "block",
        width: "100%",
        fontWeight: 500
    },
    sectionTitleFlex: {
        display: "flex"
    },
    sectionTitleRight: {
        flexGrow: 1,
        textAlign: "right",
        color: theme.palette.text.hint
    },
    viewer: {
        height: "640px",
        width: "100%"
    },
    viewerInvisible: {
        visibility: "hidden"
    },
    viewerContainer: {
        position: "relative",
        height: "640px",
        "&>div": {
            position: "absolute"
        }
    },
    settingsSpacer: {
        flexGrow: 1
    }
}));

declare type ViewerData = BpmnViewerData & DmnViewerData & {
    type: "bpmn" | "dmn";
};

const CoverageViewer: React.FC<Props> = props => {
    const classes = useStyles();

    const [selectedType, setSelectedType] = useState<"dmn" | "bpmn" | undefined>(undefined);
    const [selectedModel, setSelectedModel] = useState<BpmnModelRO | DmnModelRO | undefined>(undefined);
    const [selectedClass, setSelectedClass] = useState<BpmnTestClassRO | DmnTestClassRO | undefined>(undefined);
    const [selectedMethod, setSelectedMethod] = useState<BpmnTestMethodRO | DmnTestMethodRO | undefined>(undefined);

    const [playOpen, setPlayOpen] = useState<boolean>(false);
    const [playModel, setPlayModel] = useState<BpmnModelRO | undefined>(undefined);
    const [playClass, setPlayClass] = useState<BpmnTestClassRO | undefined>(undefined);
    const [playMethod, setPlayMethod] = useState<BpmnTestMethodRO | undefined>(undefined);

    const [data, setData] = useState<ViewerData | undefined>(undefined);
    const [bpmnListener, setBpmnListener] = useState<BpmnViewerListener | undefined>(undefined);

    const [showCoverage, setShowCoverage] = useState(true);
    const [showTransactionBoundaries, setShowTransactionBoundaries] = useState(false);
    const [showExpressions, setShowExpressions] = useState(false);

    const download = useCallback(() => {
        if (selectedType === "bpmn" && selectedModel) {
            const model = selectedModel as BpmnModelRO;
            downloadFile(model.processDefinitionKey + ".bpmn", model.bpmnXml);
        }

        if (selectedType === "dmn" && selectedModel) {
            const model = selectedModel as DmnModelRO;
            downloadFile(model.decisionKey + ".dmn", model.dmnXml);
        }
    }, [selectedType, selectedModel]);

    const updateModel = useCallback((
        type: "dmn" | "bpmn",
        model: BpmnModelRO | DmnModelRO,
        testClass?: BpmnTestClassRO | DmnTestClassRO,
        testMethod?: BpmnTestMethodRO | DmnTestMethodRO
    ) => {
        setSelectedType(type);
        setSelectedModel(model);
        setSelectedClass(testClass);
        setSelectedMethod(testMethod);
        if (type === "bpmn") {
            const bpmnModel = model as BpmnModelRO;
            const bpmnClass = testClass as BpmnTestClassRO | undefined;
            const bpmnMethod = testMethod as BpmnTestMethodRO | undefined;

            if (bpmnMethod) { // BPMN Method selected
                setData({
                    type: "bpmn",
                    xml: bpmnModel.bpmnXml,
                    highlightFlowNodes: bpmnMethod.coveredFlowNodes.map(n => n.key),
                    highlightSequenceFlows: bpmnMethod.coveredSequenceFlows.map(f => f.key),
                    highlightRules: [],
                    selectedRule: ""
                });
            } else if (bpmnClass) { // BPMN Class selected
                setData({
                    type: "bpmn",
                    xml: bpmnModel.bpmnXml,
                    highlightFlowNodes: bpmnClass.coveredFlowNodes.map(n => n.key),
                    highlightSequenceFlows: bpmnClass.coveredSequenceFlows.map(f => f.key),
                    highlightRules: [],
                    selectedRule: ""
                });
            } else {
                setData({ // BPMN Model selected
                    type: "bpmn",
                    xml: bpmnModel.bpmnXml,
                    highlightFlowNodes: bpmnModel.coveredFlowNodes.map(n => n.key),
                    highlightSequenceFlows: bpmnModel.coveredSequenceFlows.map(f => f.key),
                    highlightRules: [],
                    selectedRule: ""
                });
            }
        } else {
            const dmnModel = model as DmnModelRO;
            const dmnClass = testClass as DmnTestClassRO | undefined;
            const dmnMethod = testMethod as DmnTestMethodRO | undefined;

            if (dmnMethod) { // DMN Method selected
                setData({
                    type: "dmn",
                    xml: dmnModel.dmnXml,
                    highlightFlowNodes: [],
                    highlightSequenceFlows: [],
                    highlightRules: dmnMethod.coveredRules,
                    selectedRule: dmnModel.decisionKey
                });
            } else if (dmnClass) { // DMN Class selected
                setData({
                    type: "dmn",
                    xml: dmnModel.dmnXml,
                    highlightFlowNodes: [],
                    highlightSequenceFlows: [],
                    highlightRules: dmnClass.coveredRules,
                    selectedRule: dmnModel.decisionKey
                });
            } else {
                setData({ // DMN Model selected
                    type: "dmn",
                    xml: dmnModel.dmnXml,
                    highlightFlowNodes: [],
                    highlightSequenceFlows: [],
                    highlightRules: dmnModel.coveredRules,
                    selectedRule: dmnModel.decisionKey
                });
            }
        }
    }, []);

    const onPlayClicked = useCallback((
        selectedModel: BpmnModelRO,
        selectedClass: BpmnTestClassRO,
        selectedMethod: BpmnTestMethodRO
    ) => {
        setPlayModel(selectedModel);
        setPlayClass(selectedClass);
        setPlayMethod(selectedMethod);
        setPlayOpen(true);
    }, []);

    const onPlayClosed = useCallback(() => {
        setPlayOpen(false);
        setPlayModel(undefined);
        setPlayClass(undefined);
        setPlayMethod(undefined);
    }, []);

    useEffect(() => {
        if (props.build) {
            if (props.build.bpmnModels.length > 0) {
                const model = props.build.bpmnModels[0];
                updateModel("bpmn", model);
            } else if (props.build.dmnModels.length > 0) {
                const model = props.build.dmnModels[0];
                updateModel("dmn", model);
            }
        }
    }, [props.build, updateModel, onPlayClicked]);

    if (!props.build) {
        return null;
    }

    return (
        <>
            <div className={clsx(classes.sectionContainer, classes.sectionContainerLarge)}>
                <div className={classes.sectionTitle}>
                    <span>Model Viewer</span>
                </div>
                <div className={classes.settings}>

                    <FormControlLabel
                        label="Show Coverage"
                        className={classes.settingsLabel}
                        control={
                            <Checkbox
                                size="small"
                                color="primary"
                                checked={showCoverage}
                                onChange={(e, checked) => setShowCoverage(checked)}
                                name="ShowCoverage"/>
                        }/>

                    {selectedType === "bpmn" && (
                        <>
                            <FormControlLabel
                                label="Show Transaction Boundaries"
                                className={classes.settingsLabel}
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={showTransactionBoundaries}
                                        onChange={(e, checked) => setShowTransactionBoundaries(checked)}
                                        name="ShowTransactionBoundaries"/>
                                }/>

                            <FormControlLabel
                                label="Show Expressions"
                                className={classes.settingsLabel}
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={showExpressions}
                                        onChange={(e, checked) => setShowExpressions(checked)}
                                        name="ShowExpressions"/>
                                }/>
                        </>
                    )}

                    <div className={classes.settingsSpacer}/>

                    {selectedType === "bpmn" && (
                        <>
                            <IconButton
                                title="Zoom In"
                                onClick={() => bpmnListener?.send("ZOOM_IN")}>
                                <ZoomIn/>
                            </IconButton>

                            <IconButton
                                title="Zoom Out"
                                onClick={() => bpmnListener?.send("ZOOM_OUT")}>
                                <ZoomOut/>
                            </IconButton>

                            <IconButton
                                title="Reset Zoom"
                                onClick={() => bpmnListener?.send("RESET_ZOOM")}>
                                <CenterFocusStrong/>
                            </IconButton>
                        </>
                    )}

                    <IconButton
                        disabled={!data || !selectedModel}
                        title={selectedType === "bpmn" ? "Download BPMN" : "Download DMN"}
                        onClick={download}>
                        <CloudDownload/>
                    </IconButton>

                </div>

                {!playOpen && (
                    <div className={classes.viewerContainer}>
                        <BpmnViewer
                            render={selectedType === "bpmn"}
                            className={clsx(classes.viewer, selectedType !== "bpmn" && classes.viewerInvisible)}
                            showCoverage={showCoverage}
                            showExpressions={showExpressions}
                            showTransactionBoundaries={showTransactionBoundaries}
                            setListener={setBpmnListener}
                            data={data}/>

                        <DmnViewer
                            render={selectedType === "dmn"}
                            className={clsx(classes.viewer, selectedType !== "dmn" && classes.viewerInvisible)}
                            showCoverage={showCoverage}
                            data={data}/>
                    </div>
                )}

            </div>

            <BuildSummary
                selectedMethod={selectedMethod}
                selectedClass={selectedClass}
                selectedModel={selectedModel}
                onSelectionChanged={updateModel}
                onPlayClicked={onPlayClicked}
                build={props.build}/>

            {playOpen && (
                <CoveragePlayer
                    selectedModel={playModel}
                    selectedClass={playClass}
                    selectedMethod={playMethod}
                    onClosed={onPlayClosed}/>
            )}
        </>
    );
};

export default CoverageViewer;