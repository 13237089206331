import { apiGet, apiPost, apiPut, apiDelete, ApiResponse } from "@flowsquad/react-utils-api";

export interface AssignedRepositoryRO {
    bpmnCoverage?: number;
    branchName?: string;
    commitAuthor?: string;
    commitId?: string;
    commitMessage?: string;
    coverage?: number;
    createdAt: string;
    dmnCoverage?: number;
    id: string;
    lastBuildId?: string;
    modifiedAt: string;
    name: string;
    role: "OWNER" | "ADMIN" | "MEMBER" | "VIEWER";
    url: string;
}

export interface BpmnModelRO {
    bpmnXml: string;
    classes: BpmnTestClassRO[];
    coverage: number;
    coveredFlowNodeCount: number;
    coveredFlowNodes: FlowNodeRO[];
    coveredSequenceFlowCount: number;
    coveredSequenceFlows: SequenceFlowRO[];
    processDefinitionKey: string;
    totalNodeCount: number;
}

export interface BpmnTestClassRO {
    coverage: number;
    coveredFlowNodeCount: number;
    coveredFlowNodes: FlowNodeRO[];
    coveredSequenceFlowCount: number;
    coveredSequenceFlows: SequenceFlowRO[];
    methods: BpmnTestMethodRO[];
    name: string;
    totalNodeCount: number;
}

export interface BpmnTestMethodRO {
    coverage: number;
    coveredFlowNodeCount: number;
    coveredFlowNodes: FlowNodeRO[];
    coveredSequenceFlowCount: number;
    coveredSequenceFlows: SequenceFlowRO[];
    name: string;
    totalNodeCount: number;
}

export interface BuildPreviewRO {
    averageCoverage: number;
    bpmnCoverage: number;
    branchName?: string;
    ci: boolean;
    commitAuthor?: string;
    commitId?: string;
    commitMessage?: string;
    coveredFlowNodeCount: number;
    coveredSequenceFlowCount: number;
    createdAt: string;
    dmnCoverage: number;
    id: string;
    totalNodeCount: number;
}

export interface BuildRO {
    averageCoverage: number;
    bpmnCoverage: number;
    bpmnModels: BpmnModelRO[];
    branchName?: string;
    ci: boolean;
    commitAuthor?: string;
    commitId?: string;
    commitMessage?: string;
    coveredDmnRules: number;
    coveredFlowNodeCount: number;
    coveredSequenceFlowCount: number;
    createdAt: string;
    dmnCoverage: number;
    dmnModels: DmnModelRO[];
    id: string;
    totalNodeCount: number;
    totalRuleCount: number;
}

export interface DmnModelRO {
    classes: DmnTestClassRO[];
    coverage: number;
    coveredRuleCount: number;
    coveredRules: string[];
    decisionKey: string;
    dmnXml: string;
    totalRuleCount: number;
}

export interface DmnTestClassRO {
    coverage: number;
    coveredRuleCount: number;
    coveredRules: string[];
    methods: DmnTestMethodRO[];
    name: string;
    totalRuleCount: number;
}

export interface DmnTestMethodRO {
    coverage: number;
    coveredRuleCount: number;
    coveredRules: string[];
    name: string;
    totalRuleCount: number;
}

export interface FlowNodeRO {
    executionEndCounter: number;
    executionStartCounter: number;
    instanceId: string;
    key: string;
    type: string;
}

export interface GrantRO {
    email: string;
    repositoryId: string;
    role: "OWNER" | "ADMIN" | "MEMBER" | "VIEWER";
}

export interface InvitationRO {
    email: string;
    repositoryId: string;
    role: "OWNER" | "ADMIN" | "MEMBER" | "VIEWER";
}

export interface NewBpmnModelRO {
    bpmnXml: string;
    hash: number;
    name: string;
    processDefinitionKey: string;
    testClasses: NewBpmnTestClassRO[];
    totalNodeCount: number;
    version: string;
}

export interface NewBpmnTestClassRO {
    executionEndTime: string;
    name: string;
    testMethods: NewBpmnTestMethodRO[];
}

export interface NewBpmnTestMethodRO {
    flowNodes: FlowNodeRO[];
    name: string;
    sequenceFlows: SequenceFlowRO[];
}

export interface NewBuildRO {
    bpmnModels: NewBpmnModelRO[];
    dmnModels: NewDmnModelRO[];
}

export interface NewDmnModelRO {
    decisionKey: string;
    dmnXml: string;
    hash: number;
    name: string;
    ruleCount: number;
    testClasses: NewDmnTestClassRO[];
    version: string;
}

export interface NewDmnTestClassRO {
    executionEndTime: string;
    name: string;
    testMethods: NewDmnTestMethodRO[];
}

export interface NewDmnTestMethodRO {
    name: string;
    rules: RuleRO[];
}

export interface NewRepositoryRO {
    name: string;
    url: string;
}

export interface RepositoryUpdateRO {
    name: string;
    repositoryId: string;
}

export interface RepositoryUserRO {
    email: string;
    role: "OWNER" | "ADMIN" | "MEMBER" | "VIEWER";
}

export interface RuleRO {
    key: string;
}

export interface SequenceFlowRO {
    executionStartCounter: number;
    key: string;
}

export interface TestResultRO {
    branchName?: string;
    ci: boolean;
    commitAuthor?: string;
    commitId?: string;
    commitMessage?: string;
    data: NewBuildRO[];
    repositoryId: string;
}

export interface UserRO {
    apiKey: string;
    email: string;
    id: string;
}

export interface UserRemovalRO {
    email: string;
    repositoryId: string;
}

// Shortcut for Async Response
declare type AR<T> = Promise<ApiResponse<T>>;

// noinspection JSUnusedGlobalSymbols
export class BuildAPI {
    static loadBuildPreviewsByRepositoryId = async (id: string): AR<BuildPreviewRO[]> => {
        return await apiGet<BuildPreviewRO[]>(`/api/v0/build/preview/repository/${id}`);
    };

    static loadBuildsByRepositoryId = async (id: string): AR<BuildRO[]> => {
        return await apiGet<BuildRO[]>(`/api/v0/build/repository/${id}`);
    };

    static createBuild = async (apiKey: string, testResultRO: TestResultRO): AR<string> => {
        return await apiPost<string>(`/api/v0/build/upload?apiKey=${apiKey}`, testResultRO);
    };

    static loadBuild = async (id: string): AR<BuildRO> => {
        return await apiGet<BuildRO>(`/api/v0/build/${id}`);
    };
}

// noinspection JSUnusedGlobalSymbols
export class RepositoryAPI {
    static loadRepositories = async (): AR<AssignedRepositoryRO[]> => {
        return await apiGet<AssignedRepositoryRO[]>(`/api/v0/repository`);
    };

    static createRepository = async (newRepositoryRO: NewRepositoryRO): AR<string> => {
        return await apiPost<string>(`/api/v0/repository`, newRepositoryRO);
    };

    static updateRepository = async (repositoryUpdateRO: RepositoryUpdateRO): AR<void> => {
        return await apiPut<void>(`/api/v0/repository`, repositoryUpdateRO);
    };

    static loadDeletedRepositories = async (): AR<AssignedRepositoryRO[]> => {
        return await apiGet<AssignedRepositoryRO[]>(`/api/v0/repository/deleted`);
    };

    static grantUserRole = async (grantRO: GrantRO): AR<void> => {
        return await apiPost<void>(`/api/v0/repository/grant`, grantRO);
    };

    static inviteUser = async (invitationRO: InvitationRO): AR<void> => {
        return await apiPost<void>(`/api/v0/repository/invite`, invitationRO);
    };

    static removeUser = async (userRemovalRO: UserRemovalRO): AR<void> => {
        return await apiPost<void>(`/api/v0/repository/remove`, userRemovalRO);
    };

    static deleteRepository = async (id: string): AR<void> => {
        return await apiDelete<void>(`/api/v0/repository/${id}`);
    };

    static leaveRepository = async (id: string): AR<void> => {
        return await apiPost<void>(`/api/v0/repository/${id}/leave`);
    };

    static restoreRepository = async (id: string): AR<void> => {
        return await apiPost<void>(`/api/v0/repository/${id}/restore`);
    };

    static loadRepositoryUsers = async (id: string): AR<RepositoryUserRO[]> => {
        return await apiGet<RepositoryUserRO[]>(`/api/v0/repository/${id}/user`);
    };
}

// noinspection JSUnusedGlobalSymbols
export class UserAPI {
    static refreshApiKey = async (): AR<UserRO> => {
        return await apiPost<UserRO>(`/api/v0/user/apikey`);
    };

    static loadUserInfo = async (): AR<UserRO> => {
        return await apiGet<UserRO>(`/api/v0/user/info`);
    };
}
