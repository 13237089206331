import {UserAPI} from "../api/api";
import createObjectApiState from "../state-test/ObjectApiState";

const [userInfoSlice, loadUserInfo, updateUserInfoState] = createObjectApiState({
    execute: UserAPI.loadUserInfo,
    cacheTimeout: 3600,
    name: "UserInfo"
});

export {
    userInfoSlice, loadUserInfo, updateUserInfoState
};