import {AppBar, Dialog, IconButton, Theme, Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
    CenterFocusStrong,
    Close,
    FastForward,
    FastRewind,
    Pause,
    PlayArrow,
    PlayCircleOutline,
    SkipNext,
    SkipPrevious,
    SlowMotionVideo,
    Timelapse,
    ZoomIn,
    ZoomOut
} from "@material-ui/icons";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import clsx from "clsx";
import React, {useCallback, useEffect, useState} from "react";
import {BpmnModelRO, BpmnTestClassRO, BpmnTestMethodRO} from "../../api/api";
import BpmnPlayer, {
    ExternalPlayerEvent,
    ExternalPlayerListener,
    PlayerEvent,
    PlayerListener,
    PlayerSpeed
} from "./BpmnPlayer";
import PlayerTransition from "./PlayerTransition";

interface Props {
    onClosed: () => void;
    selectedModel?: BpmnModelRO;
    selectedClass?: BpmnTestClassRO;
    selectedMethod?: BpmnTestMethodRO;
}

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        paddingRight: "0 !important",
        backgroundColor: theme.palette.primary.main
    },
    appBarSection: {
        flexGrow: 1,
        flexBasis: 1,
        display: "flex",
        alignItems: "center"
    },
    appBarSectionLeft: {
        justifyContent: "flex-start"
    },
    appBarSectionCenter: {
        justifyContent: "center"
    },
    appBarSectionRight: {
        justifyContent: "flex-end"
    },
    paper: {
        display: "flex",
        // App Bar
        paddingTop: "64px",
        backgroundColor: theme.palette.background.default,
        flexGrow: 1
    },
    speedButtons: {
        marginRight: "1rem"
    },
    iconButton: {
        color: "rgba(255, 255, 255, 0.87)",
        transition: theme.transitions.create("color")
    },
    iconButtonDisabled: {
        color: "rgba(255, 255, 255, 0.3) !important"
    },
    toggleButton: {
        borderColor: "rgba(255, 255, 255, 0.54) !important",
        color: "rgba(255, 255, 255, 0.87) !important",
        transition: theme.transitions.create(["background-color", "color"])
    },
    toggleButtonActive: {
        backgroundColor: "rgba(255, 255, 255, 0.2) !important",
        color: "rgba(255, 255, 255, 1.0) !important"
    },
    legend: {
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "24px",
        display: "flex",
        alignItems: "center",
        color: theme.palette.text.primary
    },
    dot: {
        borderRadius: "50%",
        height: "16px",
        width: "16px",
        marginRight: "6px",
        marginLeft: "12px"
    },
    dotBlue: {
        backgroundColor: "rgb(152, 201, 249)"
    },
    dotGreen: {
        backgroundColor: "rgb(168, 231, 161)"
    },
    dotYellow: {
        backgroundColor: "rgb(255, 223, 150)"
    }
}));

const CoveragePlayer: React.FC<Props> = props => {
    const classes = useStyles();

    const [eventDispatcher, setEventDispatcher] = useState<PlayerListener | undefined>(undefined);
    const [speed, setSpeed] = useState<PlayerSpeed>("medium");
    const [playing, setPlaying] = useState(false);
    const [playDisabled, setPlayDisabled] = useState(false);
    const [backStartDisabled, setBackStartDisabled] = useState(true);
    const [backStepDisabled, setBackStepDisabled] = useState(true);
    const [nextEndDisabled, setNextEndDisabled] = useState(false);
    const [nextStepDisabled, setNextStepDisabled] = useState(false);

    const listener: ExternalPlayerListener = useCallback((event: ExternalPlayerEvent) => {
        switch (event) {
            case "DISABLE_BACK_START":
                setBackStartDisabled(true);
                break;
            case "DISABLE_BACK_STEP":
                setBackStepDisabled(true);
                break;
            case "DISABLE_NEXT_END":
                setNextEndDisabled(true);
                break;
            case "DISABLE_NEXT_STEP":
                setNextStepDisabled(true);
                break;
            case "DISABLE_PLAY":
                setPlayDisabled(true);
                break;
            case "ENABLE_BACK_START":
                setBackStartDisabled(false);
                break;
            case "ENABLE_BACK_STEP":
                setBackStepDisabled(false);
                break;
            case "ENABLE_NEXT_END":
                setNextEndDisabled(false);
                break;
            case "ENABLE_NEXT_STEP":
                setNextStepDisabled(false);
                break;
            case "ENABLE_PLAY":
                setPlayDisabled(false);
                break;
            case "PLAY_PAUSED":
                setPlaying(false);
                break;
            case "PLAY_STARTED":
                setPlaying(true);
                break;
        }
    }, []);

    const sendEvent = useCallback((event: PlayerEvent) => {
        eventDispatcher && eventDispatcher.dispatch(event);
    }, [eventDispatcher]);

    useEffect(() => {
        switch (speed) {
            case "fast":
                sendEvent("SPEED_FAST");
                break;
            case "medium":
                sendEvent("SPEED_MEDIUM");
                break;
            case "slow":
                sendEvent("SPEED_SLOW");
                break;
        }
    }, [speed, sendEvent]);

    if (!props.selectedModel || !props.selectedClass || !props.selectedMethod) {
        return null;
    }

    const toggleClasses = {
        root: classes.toggleButton,
        selected: classes.toggleButtonActive
    };

    return (
        <Dialog fullScreen open={true} TransitionComponent={PlayerTransition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <div className={clsx(classes.appBarSection, classes.appBarSectionLeft)}>
                        <IconButton
                            title="Zoom In"
                            className={classes.iconButton}
                            onClick={() => sendEvent("ZOOM_IN")}>
                            <ZoomIn/>
                        </IconButton>

                        <IconButton
                            title="Zoom Out"
                            className={classes.iconButton}
                            onClick={() => sendEvent("ZOOM_OUT")}>
                            <ZoomOut/>
                        </IconButton>

                        <IconButton
                            title="Reset Zoom"
                            className={classes.iconButton}
                            onClick={() => sendEvent("RESET_ZOOM")}>
                            <CenterFocusStrong/>
                        </IconButton>
                    </div>
                    <div className={clsx(classes.appBarSection, classes.appBarSectionCenter)}>

                        <IconButton
                            title="Jump To Start"
                            className={clsx(classes.iconButton, backStartDisabled && classes.iconButtonDisabled)}
                            disabled={backStartDisabled}
                            onClick={() => sendEvent("BACK_START")}>
                            <SkipPrevious/>
                        </IconButton>

                        <IconButton
                            title="Step Back"
                            className={clsx(classes.iconButton, backStepDisabled && classes.iconButtonDisabled)}
                            disabled={backStepDisabled}
                            onClick={() => sendEvent("BACK_STEP")}>
                            <FastRewind/>
                        </IconButton>

                        <IconButton
                            title={playing ? "Pause" : "Play"}
                            className={clsx(classes.iconButton, playDisabled && classes.iconButtonDisabled)}
                            disabled={playDisabled}
                            onClick={() => sendEvent(playing ? "PAUSE" : "PLAY")}>
                            {playing ? <Pause/> : <PlayArrow/>}
                        </IconButton>

                        <IconButton
                            title="Step Forward"
                            className={clsx(classes.iconButton, nextStepDisabled && classes.iconButtonDisabled)}
                            disabled={nextStepDisabled}
                            onClick={() => sendEvent("NEXT_STEP")}>
                            <FastForward/>
                        </IconButton>

                        <IconButton
                            title="Jump To End"
                            className={clsx(classes.iconButton, nextEndDisabled && classes.iconButtonDisabled)}
                            disabled={nextEndDisabled}
                            onClick={() => sendEvent("NEXT_END")}>
                            <SkipNext/>
                        </IconButton>

                    </div>
                    <div className={clsx(classes.appBarSection, classes.appBarSectionRight)}>
                        <ToggleButtonGroup
                            className={classes.speedButtons}
                            value={speed}
                            exclusive
                            size="small"
                            onChange={(e, newSpeed) => newSpeed !== speed && setSpeed(newSpeed)}>
                            <ToggleButton
                                title="Slow Animation Speed"
                                classes={toggleClasses}
                                value="slow">
                                <SlowMotionVideo/>
                            </ToggleButton>
                            <ToggleButton
                                title="Medium Animation Speed"
                                classes={toggleClasses}
                                value="medium">
                                <PlayCircleOutline/>
                            </ToggleButton>
                            <ToggleButton
                                title="Fast Animation Speed"
                                classes={toggleClasses}
                                value="fast">
                                <Timelapse/>
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <IconButton color="inherit" onClick={props.onClosed}>
                            <Close/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <div className={classes.legend}>
                <div className={clsx(classes.dotBlue, classes.dot)} />
                Started
                <div className={clsx(classes.dotYellow, classes.dot)} />
                Partially Covered
                <div className={clsx(classes.dotGreen, classes.dot)} />
                Covered
            </div>
            <div className={classes.paper}>
                <BpmnPlayer
                    onEvent={listener}
                    setEventDispatcher={setEventDispatcher}
                    flows={props.selectedMethod.coveredSequenceFlows}
                    nodes={props.selectedMethod.coveredFlowNodes}
                    xml={props.selectedModel.bpmnXml}/>
            </div>
        </Dialog>
    );
};

export default CoveragePlayer;