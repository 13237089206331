import {AssignedRepositoryRO} from "../api/api";

export const ROLE_PRIORITY = {
    "OWNER": 4,
    "ADMIN": 3,
    "MEMBER": 2,
    "VIEWER": 1
};

export declare type UserRole = "OWNER" | "ADMIN" | "MEMBER" | "VIEWER";
export const isAtLeast = (role: UserRole, repository: AssignedRepositoryRO | undefined): boolean => {
    return !!repository && ROLE_PRIORITY[repository.role] >= ROLE_PRIORITY[role];
};
export const roleIsAtLeast = (role: UserRole, userRole: UserRole): boolean => {
    return ROLE_PRIORITY[userRole] >= ROLE_PRIORITY[role];
};