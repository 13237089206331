import React from "react";
import {AssignedRepositoryRO} from "../../../api/api";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";
import SmallButton from "../../../components/Layout/PageLayout/SmallButton";

interface Props {
    repository: AssignedRepositoryRO | undefined;
}

const UserRole: React.FC<Props> = props => {
    const {repository} = props;

    return (
        <Section title="Your repository membership">

            <SectionDescription>
                You are member of this repository. You currently have the role <b>{"" +
            repository?.role.substr(0, 1) + repository?.role.substr(1).toLowerCase()}</b>.
                To see the permissions you have, open the link below.
            </SectionDescription>

            <SmallButton
                openNewTab
                link="https://docs.flowcov.io/getting-started/collaborating"
                text="Learn more"/>

        </Section>
    );
};

export default UserRole;