import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React, {FC} from "react";

interface Props {
    dmnCoverage: number | undefined;
    bpmnCoverage: number | undefined;
    totalCoverage: number | undefined;
    alt?: string;
    size?: number;
    showText?: boolean;
    thickness?: number;
    className?: string;
    textClassName?: string;
}

const useStyles = makeStyles(() => ({
    background: {
        color: "lightgrey"
    },
    foreground1: {
        color: "lightgreen",
        position: "absolute",
        left: 0
    },
    foreground2: {
        color: "green",
        position: "absolute",
        left: 0
    },
    container: {
        position: "relative",
        margin: "auto"
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        fontSize: "0.8rem"
    }
}));

const toPercent = (percent: number, digits: number) => (percent * 100).toFixed(digits) + "%";

const CoverageCircle: FC<Props> = props => {
    const classes = useStyles();

    const bpmnCoverage = !props.bpmnCoverage ? "n/a" : toPercent(props.bpmnCoverage, 2);
    const dmnCoverage = !props.dmnCoverage ? "n/a" : toPercent(props.dmnCoverage, 2);
    const totalCoverageDetailed = !props.totalCoverage ? "n/a" : toPercent(props.totalCoverage, 2);
    const totalCoverage = !props.totalCoverage ? "n/a" : toPercent(props.totalCoverage, 0);

    const alt = props.alt || "Total Coverage: " + totalCoverageDetailed + "\n" +
        "BPMN Coverage: " + bpmnCoverage + "\n" +
        "DMN Coverage: " + dmnCoverage;

    return (
        <div
            title={alt}
            className={clsx(classes.container, props.className)}>

            <CircularProgress
                className={classes.background}
                value={100}
                size={props.size || 64}
                thickness={props.thickness || 6}
                variant="static"/>

            <CircularProgress
                className={classes.foreground1}
                value={(props.dmnCoverage || 0) * 100}
                size={props.size || 64}
                thickness={props.thickness || 6}
                variant="static"/>

            <CircularProgress
                className={classes.foreground2}
                value={(props.bpmnCoverage || 0) * 100}
                size={props.size || 64}
                thickness={props.thickness || 6}
                variant="static"/>

            {/* Fallback if only total coverage is set */}
            {!props.bpmnCoverage && !props.dmnCoverage && props.totalCoverage && (
                <CircularProgress
                    className={classes.foreground2}
                    value={props.totalCoverage * 100}
                    size={props.size || 64}
                    thickness={props.thickness || 6}
                    variant="static"/>
            )}

            {props.showText && (
                <span className={clsx(classes.text, props.textClassName)}>
                    {totalCoverage}
                </span>
            )}

        </div>
    );
};

export default CoverageCircle;