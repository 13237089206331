import {Tooltip as MaterialTooltip} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import React from "react";

/**
 * Copyright 2020 FlowSquad GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

interface Props {
    title: string;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
}


const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        fontSize: "0.85rem",
        backgroundColor: "rgba(0, 0, 0, 0.87)"
    },
    tooltipArrow: {
        color: "rgba(0, 0, 0, 0.87)"
    },
}));

export const Tooltip: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <MaterialTooltip
            title={props.title}
            arrow
            placement={props.placement || "top"}
            classes={{
                arrow: classes.tooltipArrow,
                tooltip: classes.tooltip
            }}>
            <span>{props.children}</span>
        </MaterialTooltip>
    );
};