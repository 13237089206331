import {ApiResponse, hasFailed, SuccessfulResponse} from "@flowsquad/react-utils-api";
import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
    createSlice,
    Draft,
    PayloadAction
} from "@reduxjs/toolkit";
import {RootState} from "../state/Store";
import {
    ApiState,
    LoadFailed, LoadFailedPayload,
    LoadStarted,
    LoadSuccessfulPayload,
    reduceLoadFailed,
    reduceLoadStarted
} from "./ApiState";

/**
 * Copyright 2020 FlowSquad GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * The representation of a redux store state. Useful if you load data indexed by an id from an API and want to cache it.
 * You can then trigger reloads or manual updates on it and still use the current value.
 *
 * @template IdKey  The key of type that is used to index the map
 * @template Type   The type of the value contained in this state
 */
export interface ObjectApiState<Type> extends ApiState {
    /**
     * The actual value of the state. It consists of a single object that will be replaced on each successful request.
     * If a request fails, no changes to this field will be made. If there has been no successful request yet, the
     * value will be undefined.
     */
    readonly value: Type | undefined;
}

/**
 * The representation of the payload of a "load successful" action.
 *
 * @template Type The type of the actual result
 */
export interface ObjectLoadSuccessfulPayload<Type> extends LoadSuccessfulPayload {
    /**
     * The actual result of the request. Will replace the existing value.
     */
    value: Type;
}

/**
 * The representation of the payload of a manually triggered "update state" action.
 *
 * @template Type   The type of the actual result
 */
export interface ObjectUpdateStatePayload<Type> {
    /**
     * The new value to use.
     */
    value: Type;
}

/**
 * Constants used for creating the action names.
 */
const ObjectLoadSucceeded = "ObjectLoadSucceeded";
const ObjectUpdateState = "ObjectUpdateState";

/**
 * Create initial state for every api state.
 *
 * @param name The globally unique name of this slice
 */
const createInitialObjectApiState = (name: string) => ({
    loading: false,
    name: name,
    value: undefined
});

/**
 * Processes a succeeded request. Sets the loading state to false, saves value, status code, load time and resets the
 * error.
 *
 * @param state The current state
 * @param action The "load succeeded" action
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
const reduceObjectLoadSucceeded = <Type>(
    state: Draft<ObjectApiState<Type>>,
    action: PayloadAction<ObjectLoadSuccessfulPayload<Type>>
) => {
    state.loading = false;
    state.statusCode = action.payload.statusCode;
    state.loadTime = new Date().getTime();
    state.error = undefined;
    state.value = action.payload.value as Draft<Type>;
};

/**
 * Processes a manually triggered "update state" request. Sets the new value and does not change anything else.
 *
 * @param state The current state
 * @param action The "update state" action
 *
 * @template Type   The type of the actual result
 */
const reduceObjectUpdateState = <Type>(
    state: Draft<ObjectApiState<Type>>,
    action: PayloadAction<ObjectUpdateStatePayload<Type>>
) => {
    state.value = action.payload.value as Draft<Type>;
};

/**
 * Tries to find the state of the slice with the specified name from the root state.
 *
 * @param state The root state
 * @param sliceName The name of the slice to find
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
const getObjectSliceState = <Type>(state: RootState, sliceName: string): ObjectApiState<Type> | undefined => {
    return Object.values(state).find(slice => slice?.name === sliceName) as ObjectApiState<Type> | undefined;
}

/**
 * The base options for each createObjectApiState request.
 */
declare type ObjectBaseOptions = {
    name: string,
    cacheTimeout: number
};

/**
 * The options for a createObjectApiState request that does not use a custom response.
 *
 * @template Type   The type of the actual result
 */
declare type ObjectDefaultOptions<Type> = ObjectBaseOptions & {
    execute: () => Promise<ApiResponse<Type>>
};

/**
 * The options for a createObjectApiState request that does use a custom response.
 *
 * @template Type       The type of the actual result
 * @template Response   The type of the api response
 */
declare type ObjectCustomOptions<Type, Response> = ObjectBaseOptions & {
    execute: () => Promise<ApiResponse<Response>>,
    transform: (response: Response) => Type
};

/**
 * Used as options for the createObjectApiState method.
 */
declare type ObjectOptions<Type, Response> = ObjectDefaultOptions<Type> | ObjectCustomOptions<Type, Response>;

/**
 * Type assertion to check if the options object uses a custom response transformator.
 *
 * @param param The options object to check
 */
const isCustomObjectResponse = <Type, Response>(
    param: ObjectOptions<Type, Response>
): param is ObjectCustomOptions<Type, Response> => {
    return !!(param as ObjectCustomOptions<Type, Response>).transform;
};

/**
 * Creates a new object api state for usage with redux toolkit. Returns the slice and the action to start loading the
 * content. All requests must return a single object that is used as the new value. It will automatically be extracted
 * from the response, unless you specify your own transform function.
 *
 * @template Type The type of the value of the state
 * @template Response The type of the response of the api, defaults to Type
 *
 * @param options An options object with the following properties: {
 *     name:            The name of the state, must be unique
 *     cacheTimeout:    The duration for which only requests with a forceLoad flag are actually executed after a
 *                      successful load
 *     execute:         A function to actually call the API. Should return an instance of either Type or Response
 *     transform:       A function to transform the api response manually instead of using the default transform
 *                      function. Required if execute returns an instance of Response.
 * }
 *
 * @return The following items are contained in the returned array:
 *         slice: The state slice
 *         load: The action to trigger a load action
 *         updateState: The action to trigger a manual state update
 */
const createObjectApiState = <Type, Response = Type>(options: ObjectOptions<Type, Response>) => {
    const loadStartedAction = options.name + LoadStarted;
    const loadFailedAction = options.name + LoadFailed;
    const loadSucceededAction = options.name + ObjectLoadSucceeded;
    const updateStateAction = options.name + ObjectUpdateState;

    const slice = createSlice({
        name: options.name,
        initialState: createInitialObjectApiState(options.name) as ObjectApiState<Type>,
        reducers: {
            [loadStartedAction]: reduceLoadStarted,
            [loadFailedAction]: reduceLoadFailed,
            [loadSucceededAction]: reduceObjectLoadSucceeded,
            [updateStateAction]: reduceObjectUpdateState
        }
    });

    const loadStarted = slice.actions[loadStartedAction] as unknown as ActionCreatorWithoutPayload; // TODO Why does this not work?
    const loadFailed = slice.actions[loadFailedAction] as ActionCreatorWithPayload<LoadFailedPayload>;
    const loadSucceeded = slice.actions[loadSucceededAction] as ActionCreatorWithPayload<ObjectLoadSuccessfulPayload<Type>>;
    const updateState = slice.actions[updateStateAction] as unknown as ActionCreatorWithPayload<ObjectUpdateStatePayload<Type>>;

    const load = (forceLoad: boolean = false) => async (dispatch: (arg: any) => void, getState: () => RootState) => {
        // Slice _must_ exist, as long as it is added to the store in Store.ts
        const state = getObjectSliceState<Type>(getState(), options.name)!;
        if (!forceLoad) {
            const loadTime = state?.loadTime || 0;
            const maxAge = new Date().getTime() - options.cacheTimeout * 1000;
            if (loadTime > maxAge) {
                return;
            }
        }

        dispatch(loadStarted());
        const result = await options.execute();
        if (hasFailed(result)) {
            dispatch(loadFailed({
                error: result.error,
                statusCode: result.status
            }));
        } else {
            let transformed: Type;
            if (isCustomObjectResponse(options)) {
                const typedResult = result as SuccessfulResponse<Response>;
                transformed = options.transform(typedResult.result);
            } else {
                const typedResult = result as SuccessfulResponse<Type>;
                transformed = typedResult.result;
            }
            dispatch(loadSucceeded({
                value: transformed,
                statusCode: result.status
            }));
        }
    };

    return [slice, load, updateState] as [typeof slice, typeof load, typeof updateState];
};

export default createObjectApiState;