import {hasFailed} from "@flowsquad/react-utils-api";
import {CircularProgress, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Book, Dashboard, Settings, VideoLibrary} from "@material-ui/icons";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {BuildAPI, BuildPreviewRO} from "../api/api";
import {retryAction, Toast} from "../components/Form/Toast";
import Commit from "../components/Icons/Commit";
import {loadRepositories} from "../state/RepositoriesState";
import {RootState} from "../state/Store";
import RepositoryBuild from "./Repository/RepositoryBuild";
import RepositoryBuilds from "./Repository/RepositoryBuilds";
import RepositoryCommits from "./Repository/RepositoryCommits";
import RepositoryDashboard from "./Repository/RepositoryDashboard";
import RepositorySettings from "./Repository/RepositorySettings";

interface RouteParams {
    repositoryId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        margin: "4rem auto"
    },
    loadingWrapper: {
        display: "flex",
        margin: "4rem",
        justifyContent: "center"
    },
    container: {
        display: "flex",
        flexDirection: "column"
    },
    header: {
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        flexDirection: "column",
        borderBottom: "2px solid #CCCCCC"
    },
    errorWrapper: {
        margin: "4rem"
    },
    tabs: {
        display: "flex",
        margin: "0rem auto",
        width: "100%",
        maxWidth: "960px"
    },
    fakeTab: {
        alignItems: "center",
        padding: "1rem",
        paddingTop: "calc(1rem + 2px)",
        display: "flex",
        fontSize: "1rem",
        fontWeight: "bold",
        textDecoration: "none",
        color: theme.palette.text.primary,
        marginBottom: "-2px",
        borderBottom: "2px solid #CCCCCC",
        backgroundColor: "rgba(0,0,0,0.05)"
    },
    tabSpacer: {
        flexGrow: 1
    },
    tab: {
        alignItems: "center",
        minWidth: "8rem",
        cursor: "pointer",
        padding: "1rem",
        paddingTop: "calc(1rem + 2px)",
        display: "flex",
        fontSize: "1rem",
        fontWeight: "bold",
        justifyContent: "center",
        textDecoration: "none",
        color: theme.palette.text.primary,
        marginBottom: "-2px",
        borderBottom: "2px solid #CCCCCC",
        transition: theme.transitions.create("background-color"),
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.05)"
        }
    },
    tabActive: {
        borderBottom: "2px solid " + theme.palette.primary.main
    },
    tabIcon: {
        marginRight: "0.5rem",
        fontSize: "1rem"
    },
    content: {
        margin: "0rem auto",
        width: "100%"
    },
    toast: {
        maxWidth: "960px",
        margin: "0 auto"
    }
}));

const RepositoryScreen: React.FC = () => {
    const classes = useStyles();
    const params = useParams<RouteParams>();

    const dispatch = useDispatch();
    const repositoriesState = useSelector((state: RootState) => state.repositories);
    const userState = useSelector((state: RootState) => state.userInfo);

    const [builds, setBuilds] = useState<BuildPreviewRO[] | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const loadBuildPreviews = useCallback(async () => {
        setError(undefined);
        const result = await BuildAPI.loadBuildPreviewsByRepositoryId(params.repositoryId);
        if (hasFailed(result)) {
            setError(result.error);
        } else {
            setBuilds(result.result);
        }
    }, [params.repositoryId]);

    useEffect(() => {
        dispatch(loadRepositories());
        loadBuildPreviews();
    }, [dispatch, loadBuildPreviews]);

    const repository = repositoriesState.value?.find(r => r.id === params.repositoryId);

    if (repositoriesState.value && !repository) {
        return (
            <div className={classes.errorWrapper}>
                <Toast message="Could not find repository."/>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            {repository && (
                <>

                    <div className={classes.header}>

                        <div className={classes.tabs}>
                            <span className={classes.fakeTab}>
                                <Book className={classes.tabIcon}/>
                                {repository.name}
                            </span>

                            <span className={classes.tabSpacer}/>

                            <NavLink
                                exact
                                to={"/repository/" + repository.id}
                                className={classes.tab}
                                activeClassName={classes.tabActive}>
                                <Dashboard className={classes.tabIcon}/>
                                Dashboard
                            </NavLink>

                            <NavLink
                                to={"/repository/" + repository.id + "/builds"}
                                className={classes.tab}
                                activeClassName={classes.tabActive}>
                                <VideoLibrary className={classes.tabIcon}/>
                                Builds
                            </NavLink>

                            <NavLink
                                to={"/repository/" + repository.id + "/commits"}
                                className={classes.tab}
                                activeClassName={classes.tabActive}>
                                <Commit className={classes.tabIcon}/>
                                Commits
                            </NavLink>

                            <NavLink
                                to={"/repository/" + repository.id + "/settings"}
                                className={classes.tab}
                                activeClassName={classes.tabActive}>
                                <Settings className={classes.tabIcon}/>
                                Settings
                            </NavLink>
                        </div>
                    </div>

                    <div className={classes.content}>

                        <Toast
                            className={classes.toast}
                            message={repositoriesState.error}
                            action={retryAction(() => dispatch(loadRepositories()))}/>

                        <Toast
                            className={classes.toast}
                            message={error}
                            action={retryAction(() => loadBuildPreviews())}/>

                        {repositoriesState.loading && !repositoriesState.value && (
                            <div className={classes.loadingWrapper}>
                                <CircularProgress size={48}/>
                            </div>
                        )}

                        <Switch>

                            <Route
                                exact
                                path="/repository/:repositoryId"
                                render={() => (
                                    <RepositoryDashboard
                                        repository={repository}
                                        builds={builds}
                                        apiKey={userState.value?.apiKey}/>
                                )}/>

                            <Route
                                exact
                                path="/repository/:repositoryId/build/:buildId"
                                component={RepositoryBuild}/>

                            <Route
                                exact
                                path="/repository/:repositoryId/builds"
                                render={() => (
                                    <RepositoryBuilds
                                        repository={repository}
                                        builds={builds}
                                        apiKey={userState.value?.apiKey}/>
                                )}/>

                            <Route
                                exact
                                path="/repository/:repositoryId/commits"
                                render={() => (
                                    <RepositoryCommits
                                        repository={repository}
                                        builds={builds}
                                        apiKey={userState.value?.apiKey}/>
                                )}/>

                            <Route
                                exact
                                path="/repository/:repositoryId/settings"
                                render={() => (
                                    <RepositorySettings
                                        repository={repository}
                                        apiKey={userState.value?.apiKey}/>
                                )}/>

                            <Redirect to="/repository/:repositoryId"/>

                        </Switch>

                    </div>

                </>
            )}

        </div>
    );
};

export default RepositoryScreen;