import React from "react";

interface Props {
    className?: string;
}

const Commit: React.FC<Props> = props => {
    return (
        <svg
            width="16"
            height="16"
            className={props.className}
            viewBox="0 0 16 16">
            <path
                fillRule="evenodd"
                d="M10.5 7.75a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm1.43.75a4.002 4.002 0 01-7.86 0H.75a.75.75 0 110-1.5h3.32a4.001 4.001 0 017.86 0h3.32a.75.75 0 110 1.5h-3.32z"/>
        </svg>
    );
};

export default Commit;

