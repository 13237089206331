import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {default as React} from "react";
import clsx from "clsx";

interface Props {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    sectionCode: {
        fontSize: "1.25rem",
        padding: "0.5rem 1rem",
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,
        marginBottom: "1rem"
    }
}));

const SectionCode: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <pre className={clsx(classes.sectionCode, props.className)}>
            {props.children}
        </pre>
    );
}

export default SectionCode;