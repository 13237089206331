import {Button, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

interface Props {
    link: string;
    text: string;
    openNewTab?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        display: "block",
        textDecoration: "none",
        marginRight: "auto"
    },
    linkButton: {
        textTransform: "none"
    }
}));

const SmallButton: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <a
            className={clsx(classes.link, props.className)}
            href={props.link}
            target={props.openNewTab ? "_blank" : undefined}
            rel={props.openNewTab ? "noopener noreferrer" : undefined}>
            <Button
                className={classes.linkButton}
                disableElevation
                color="primary"
                variant="contained"
                size="small">
                {props.text}
            </Button>
        </a>
    );
};

export default SmallButton;