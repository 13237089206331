import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Star} from "@material-ui/icons";
import React from "react";
import {AssignedRepositoryRO} from "../../api/api";
import PageHeading from "../../components/Layout/PageLayout/PageHeading";
import SmallButton from "../../components/Layout/PageLayout/SmallButton";
import {spaces} from "../../util/FormatUtils";
import {isAtLeast} from "../../util/RoleUtils";

interface Props {
    repository?: AssignedRepositoryRO;
    apiKey?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    },
    content: {
        marginTop: "2rem",
        border: "2px solid #DDDDDD",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column"
    },
    section: {
        padding: "2rem 2rem 1rem 2rem",
        display: "flex",
        flexDirection: "column"
    },
    sectionHeading: {
        fontSize: "1.25rem",
        fontWeight: "bold",
        marginBottom: "0.5rem"
    },
    sectionDescription: {
        fontSize: "0.9rem",
        marginBottom: "1rem"
    },
    sectionCode: {
        fontSize: "1.25rem",
        padding: "0.5rem 1rem",
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,
        marginBottom: "1rem"
    },
    sectionDivider: {
        height: "2px",
        backgroundColor: "#DDDDDD"
    },
    link: {
        textDecoration: "none"
    },
    linkButton: {
        textTransform: "none",
        marginBottom: "1rem"
    },
    textLink: {
        textDecoration: "none",
        color: "#0366d6",
        margin: "0 0.25rem"
    }
}));

const RepositoryInstructions: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PageHeading icon={Star}>
                Get Started
            </PageHeading>

            <div className={classes.content}>

                {props.repository?.role === "VIEWER" && (
                    <div className={classes.section}>
                        <span className={classes.sectionHeading}>
                            No builds found :(
                        </span>
                        <span className={classes.sectionDescription}>
                            There are currently no builds in this repository. Ask a repository Owner to upload a build
                            for you or grant you permissions to upload builds yourself.
                            <br/>
                            <br/>
                            <b>To upload builds, you need to have the role Member, Admin, or Owner.</b>
                        </span>
                    </div>
                )}

                {isAtLeast("MEMBER", props.repository) && (<>

                        <div className={classes.section}>
                            <span className={classes.sectionHeading}>
                                Quick Setup - if you've done this kind of thing before
                            </span>
                            <span className={classes.sectionDescription}>
                                Use this information to set up your pipeline.
                            </span>
                            <pre className={classes.sectionCode}>
                                Repository ID:{spaces(3)}<b>{props.repository?.id || "REPOSITORY_ID"}</b>
                                <br/>
                                API key:{spaces(9)}<b>{props.apiKey || "API_KEY"}</b>
                            </pre>
                        </div>

                        <div className={classes.sectionDivider}/>

                        <div className={classes.section}>
                            <span className={classes.sectionHeading}>
                                ...or learn how to create coverage reports
                            </span>
                            <span className={classes.sectionDescription}>
                                To generate coverage reports during build, you need to follow these steps:
                                <ul>
                                    <li>Add the required dependency,</li>
                                    <li>enable the coverage rule,</li>
                                    <li>and execute your tests.</li>
                                </ul>
                                To learn how to do this, click the link below.
                            </span>
                            <SmallButton
                                openNewTab
                                link="https://docs.flowcov.io/getting-started/creating-reports"
                                text="Learn more"/>
                        </div>

                        <div className={classes.sectionDivider}/>

                        <div className={classes.section}>
                            <span className={classes.sectionHeading}>
                                ...or upload existing coverage reports
                            </span>
                            <span className={classes.sectionDescription}>
                                Upload all reports by executing the following command in your build directory.
                            </span>
                            <pre className={classes.sectionCode}>
                                bash &lt;(curl -s https://bash.flowcov.io) \<br/>
                                {spaces(4)}--repository-id <b>{props.repository?.id || "Loading..."}</b> \<br/>
                                {spaces(4)}--api-key <b>{props.apiKey || "Loading..."}</b>
                            </pre>
                            <SmallButton
                                openNewTab
                                link="https://docs.flowcov.io/getting-started/uploading-reports"
                                text="Learn more"/>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default RepositoryInstructions;