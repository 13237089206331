import {FormControl, IconButton, InputLabel, MenuItem, Select, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Clear, Person} from "@material-ui/icons";
import React from "react";
import {AssignedRepositoryRO, RepositoryUserRO} from "../../../../api/api";
import {UserRole} from "../../../../util/RoleUtils";

interface Props {
    disabled: boolean;
    onCancel: () => void;
    user: RepositoryUserRO;
    repository: AssignedRepositoryRO | undefined;
    onRoleChanged: (user: RepositoryUserRO, role: UserRole) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    userRow: {
        "&:not(:first-child)": {
            borderTop: "1px solid #CCC"
        },
        padding: "0.75rem 1rem",
        display: "flex",
        alignItems: "center"
    },
    user: {
        fontSize: "1rem",
        marginLeft: "1rem"
    },
    userRole: {
        width: "75px",
        textAlign: "center",
        margin: "auto 1rem auto auto",
        fontSize: "0.9rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0.25rem",
        padding: "0.15rem 0.5rem",
        color: theme.palette.primary.contrastText,
        textTransform: "capitalize"
    },
    select: {
        marginLeft: "auto",
        width: "200px",
        marginRight: "0.5rem"
    },
    selectPaper: {
        backgroundColor: theme.palette.background.default
    }
}));

const EditMemberRow: React.FC<Props> = props => {
    const classes = useStyles();

    const isOwner = props.repository?.role === "OWNER";

    const options: {
        role: UserRole,
        title: string
    }[] = [];
    options.push({
        role: "VIEWER",
        title: "Viewer"
    });
    options.push({
        role: "MEMBER",
        title: "Member"
    });
    if (isOwner) {
        options.push({
            role: "ADMIN",
            title: "Admin"
        });
        options.push({
            role: "OWNER",
            title: "Owner"
        });
    }

    return (
        <div
            key={props.user.email}
            className={classes.userRow}>

            <Person fontSize="small"/>

            <span className={classes.user}>
                {props.user.email || "Loading email..."}
            </span>

            <FormControl
                disabled={props.disabled}
                className={classes.select}
                size="small"
                variant="outlined">
                <InputLabel id="role-label">User Role</InputLabel>
                <Select
                    MenuProps={{
                        classes: {
                            paper: classes.selectPaper
                        }
                    }}
                    labelId="role-label"
                    label="User Role"
                    variant="outlined"
                    onChange={(e => props.onRoleChanged(props.user, e.target.value as "OWNER" | "ADMIN" | "MEMBER" | "VIEWER"))}
                    value={props.user.role}>
                    {options.map(option => (
                        <MenuItem
                            key={option.title}
                            value={option.role}>
                            {option.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <IconButton
                size="small"
                disabled={props.disabled}
                onClick={props.onCancel}>
                <Clear fontSize="small"/>
            </IconButton>

        </div>
    );
};

export default EditMemberRow;