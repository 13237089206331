import {FormControl, InputLabel, MenuItem, Select, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DataUsage, History, Laptop, Memory, Person, VideoLibrary} from "@material-ui/icons";
import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {AssignedRepositoryRO, BuildPreviewRO} from "../../api/api";
import Branch from "../../components/Icons/Branch";
import Commit from "../../components/Icons/Commit";
import {compareTimestamps, timeSince} from "../../util/DateUtils";
import RepositoryInstructions from "./RepositoryInstructions";

interface Props {
    repository?: AssignedRepositoryRO;
    apiKey?: string;
    builds?: BuildPreviewRO[];
}

const getTime = (date: string): number => {
    return new Date(date).getTime();
};

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    },
    sectionContainer: {
        marginTop: "2rem",
        paddingBottom: "2rem",
        border: "2px solid rgba(34, 36, 38, 0.1)",
        backgroundColor: "rgba(255, 255, 255, 0.54)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column"
    },
    sectionTitle: {
        height: "36px",
        padding: "0.5rem",
        backgroundColor: "rgba(34, 36, 38, 0.1)",
        display: "block",
        width: "100%",
        fontWeight: 500
    },
    commit: {
        display: "flex",
        flexDirection: "column",
        padding: "0rem 2rem"
    },
    commitMessage: {
        fontSize: "1rem",
        fontWeight: 600,
        display: "flex",
        justifyItems: "center"
    },
    commitId: {
        fontSize: "1.25rem",
        marginRight: "1rem",
        fontFamily: "monospace",
        flexShrink: 0
    },
    commitIcon: {
        height: "24px",
        marginRight: "0.4rem",
        flexShrink: 0
    },
    commitText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    commitDetails: {
        marginLeft: "0.45rem",
        paddingLeft: "6rem",
        paddingBottom: "2rem",
        borderLeft: "1px solid #666666"
    },
    commitLine: {
        display: "flex",
        fontWeight: 500
    },
    commitLineItem: {
        marginRight: "1.25rem",
        display: "flex",
        alignItems: "center"
    },
    commitLineItemIcon: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.9rem",
        marginRight: "0.4rem"
    },
    commitLineItemIconExplicit: {
        fill: "rgba(0,0,0,0.54)",
        height: "16px",
        marginRight: "0.4rem"
    },
    commitBuilds: {
        marginTop: "1rem"
    },
    commitBuild: {
        display: "flex",
        color: theme.palette.text.hint
    },
    commitBuildLink: {
        textDecoration: "none",
        color: "#0366d6",
        fontSize: "1rem",
        marginRight: "0.5rem"
    },
    selectWrapper: {
        margin: "2rem",
        display: "flex"
    },
    select: {
        flexGrow: 1
    },
    selectPaper: {
        backgroundColor: theme.palette.background.default
    }
}));

const RepositoryCommits: React.FC<Props> = props => {
    const classes = useStyles();

    const [branch, setBranch] = useState<string | null>(null);

    const branches = useMemo(() => {
        let result: string[] = [];
        props.builds?.forEach(build => {
            if (build.branchName &&
                build.commitId &&
                result.indexOf(build.branchName) === -1) {
                result.push(build.branchName);
            }
        });
        return result;
    }, [props.builds]);

    const builds = useMemo(() => {
        return props.builds
            ?.filter(build => build.branchName !== null)
            .filter(build => build.commitId !== null)
            .filter(build => branch === null || build.branchName === branch);
    }, [props.builds, branch]);

    const commits = useMemo(() => {
        const commitMap: Map<string, {
            commitId: string;
            commitMessage?: string;
            commitAuthor?: string;
            branchName?: string;
            builds: BuildPreviewRO[];
            firstOccurrence: number | undefined;
        }> = new Map();

        builds?.forEach(build => {
            const commitId = build.commitId!;
            const commitMessage = build.commitMessage;
            const commitAuthor = build.commitAuthor;
            const branchName = build.branchName;

            if (!commitMap.has(commitId)) {
                commitMap.set(commitId, {
                    commitId,
                    commitMessage,
                    commitAuthor,
                    branchName,
                    builds: [],
                    firstOccurrence: undefined
                });
            }

            const existing = commitMap.get(commitId)!;

            commitMap.set(commitId, {
                ...existing,
                builds: [build, ...existing.builds],
                firstOccurrence: (existing.firstOccurrence === undefined || existing.firstOccurrence > getTime(build.createdAt))
                    ? getTime(build.createdAt) : existing.firstOccurrence
            });
        });

        return [...commitMap.values()]
            .map(commit => ({
                ...commit,
                builds: commit.builds.sort((a, b) => compareTimestamps(a.createdAt, b.createdAt))
            }))
            .sort((a, b) => (b.firstOccurrence || 0) - (a.firstOccurrence || 0));
    }, [builds]);

    return (
        <div className={classes.container}>
            {(props.builds !== undefined && props.builds.length === 0) && (
                <RepositoryInstructions
                    repository={props.repository}
                    apiKey={props.apiKey}/>
            )}
            {(props.builds?.length || 0) > 0 && (
                <div className={classes.sectionContainer}>

                    <span className={classes.sectionTitle}>
                        Commits for {branch === null ? " all branches" : ("branch " + branch)}
                    </span>

                    <div className={classes.selectWrapper}>
                        <FormControl
                            variant="outlined"
                            className={classes.select}>
                            <InputLabel id="branch-label">Select branch to filter</InputLabel>
                            <Select
                                labelId="branch-label"
                                label="Select branch to filter"
                                variant="outlined"
                                value={branch || ""}
                                onChange={(e => setBranch((e.target.value as string) || null))}
                                MenuProps={{
                                    classes: {
                                        paper: classes.selectPaper
                                    }
                                }}>
                                <MenuItem value="">
                                    <em>
                                        All branches
                                    </em>
                                </MenuItem>
                                {branches.map(branch => (
                                    <MenuItem
                                        key={branch}
                                        value={branch}>
                                        {branch}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    {commits.map(commit => (
                        <div
                            key={commit.commitId}
                            className={classes.commit}>
                            <span className={classes.commitMessage}>
                                <Commit className={classes.commitIcon}/>
                                <span className={classes.commitId}>{commit.commitId}</span>
                                <span
                                    title={commit.commitMessage || "No commit message available"}
                                    className={classes.commitText}>
                                    {commit.commitMessage || "No commit message available"}
                                </span>
                            </span>
                            <div className={classes.commitDetails}>
                                <div className={classes.commitLine}>
                                    <span className={classes.commitLineItem}>
                                        <Person className={classes.commitLineItemIcon}/>
                                        {(commit.commitAuthor?.indexOf("<") !== -1 ? commit.commitAuthor?.substring(0, commit.commitAuthor?.indexOf("<")) : commit.commitAuthor) || "n/a"}
                                    </span>
                                    <span className={classes.commitLineItem}>
                                        <Branch className={classes.commitLineItemIconExplicit}/>
                                        {commit.branchName || "n/a"}
                                    </span>
                                    <span className={classes.commitLineItem}>
                                        <VideoLibrary className={classes.commitLineItemIconExplicit}/>
                                        {commit.builds.length} Build{commit.builds.length !== 1 ? "s" : ""}
                                    </span>
                                </div>
                                <div className={classes.commitBuilds}>
                                    {commit.builds.map((build, index, array) => (
                                        <div
                                            key={build.id}
                                            className={classes.commitBuild}>
                                            <Link
                                                to={"/repository/" + props.repository?.id + "/build/" + build.id}
                                                className={classes.commitBuildLink}>
                                                Build {array.length - index}
                                            </Link>
                                            {build.ci && (
                                                <span className={classes.commitLineItem}>
                                                    <Memory className={classes.commitLineItemIcon}/>
                                                    CI
                                                </span>
                                            )}
                                            {!build.ci && (
                                                <span className={classes.commitLineItem}>
                                                    <Laptop className={classes.commitLineItemIcon}/>
                                                    Local
                                                </span>
                                            )}
                                            <span className={classes.commitLineItem}>
                                                <DataUsage className={classes.commitLineItemIcon}/>
                                                {(build.bpmnCoverage * 100).toFixed(0)}%
                                            </span>
                                            <span className={classes.commitLineItem}>
                                                <History className={classes.commitLineItemIcon}/>
                                                {timeSince(build.createdAt)} ago
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default RepositoryCommits;