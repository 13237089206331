import {makeStyles, Theme} from "@material-ui/core/styles";
import React from "react";

interface Props {
    icon: React.ElementType;
}

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: "1.25rem",
        color: theme.palette.text.primary,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        margin: "2rem auto 0 auto"
    },
    headingIcon: {
        marginRight: "0.5rem"
    },
}));

const PageHeading: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <span className={classes.heading}>
            {React.createElement(props.icon, {className: classes.headingIcon})}
            {props.children}
        </span>
    );
};

export default PageHeading;