import {hasFailed} from "@flowsquad/react-utils-api";
import {makeStyles} from "@material-ui/core/styles";
import {Refresh} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {BuildAPI, BuildRO} from "../../api/api";
import BuildRow from "../../components/Builds/BuildRow";
import {Toast} from "../../components/Form/Toast";
import Section from "../../components/Layout/PageLayout/Section";
import CoverageViewer from "../../components/Viewer/CoverageViewer";

interface Params {
    buildId: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%"
    },
    error: {
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    }
}));

const RepositoryBuild: React.FC = () => {
    const classes = useStyles();
    const params = useParams<Params>();

    const [retry, setRetry] = useState(0);
    const [retryAvailable, setRetryAvailable] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [build, setBuild] = useState<BuildRO | undefined>(undefined);

    useEffect(() => {
        (async () => {
            if (params.buildId) {
                const result = await BuildAPI.loadBuild(params.buildId);
                if (hasFailed(result)) {
                    if (result.status === 404) {
                        setError("Build not found.");
                        setRetryAvailable(false);
                    } else {
                        setError(result.error);
                        setRetryAvailable(true);
                    }
                } else {
                    setBuild(result.result);
                }
            }
        })();
    }, [params.buildId, retry]);

    return (
        <div className={classes.container}>
            <Toast
                className={classes.error}
                message={error}
                action={!retryAvailable ? undefined : {
                    icon: Refresh,
                    onClick: () => setRetry(retry => retry + 1)
                }}/>

            {build && (
                <>

                    <CoverageViewer build={build}/>

                    <Section title="Build details">
                        <BuildRow build={build} />
                    </Section>

                </>
            )}

        </div>
    );
};

export default RepositoryBuild;