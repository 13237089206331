import {FormControl, InputLabel, MenuItem, Select, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useMemo, useState} from "react";
import {AssignedRepositoryRO, BuildPreviewRO} from "../../api/api";
import BuildRow from "../../components/Builds/BuildRow";
import {compareTimestamps} from "../../util/DateUtils";
import RepositoryInstructions from "./RepositoryInstructions";

interface Props {
    repository?: AssignedRepositoryRO;
    apiKey?: string;
    builds?: BuildPreviewRO[];
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    },
    sectionContainer: {
        marginTop: "2rem",
        border: "2px solid rgba(34, 36, 38, 0.1)",
        backgroundColor: "rgba(255, 255, 255, 0.54)",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column"
    },
    sectionTitle: {
        height: "36px",
        padding: "0.5rem",
        backgroundColor: "rgba(34, 36, 38, 0.1)",
        display: "block",
        width: "100%",
        fontWeight: 500
    },
    filter: {
        display: "flex",
        padding: "2rem 1.5rem"
    },
    selectWrapper: {
        margin: "0 0.5rem",
        display: "flex",
        flexGrow: 1
    },
    select: {
        flexGrow: 1
    },
    selectPaper: {
        backgroundColor: theme.palette.background.default
    }
}));

const RepositoryBuilds: React.FC<Props> = props => {
    const classes = useStyles();

    const [branch, setBranch] = useState<string | null>(null);
    const [type, setType] = useState<"ci" | "local" | null>(null);

    const branches = useMemo(() => {
        let result: string[] = [];
        props.builds?.forEach(build => {
            if (build.branchName && result.indexOf(build.branchName) === -1) {
                result.push(build.branchName);
            }
        });
        return result;
    }, [props.builds]);

    const builds = useMemo(() => {
        return props.builds
            ?.filter(build => branch === null || (branch === "n/a" && build.branchName === null) || build.branchName === branch)
            .filter(build => type === null || (type === "ci" && build.ci) || (type === "local" && !build.ci));
    }, [props.builds, branch, type]);

    return (
        <div className={classes.container}>
            {(props.builds !== undefined && props.builds.length === 0) && (
                <RepositoryInstructions
                    repository={props.repository}
                    apiKey={props.apiKey}/>
            )}
            {(props.builds?.length || 0) > 0 && (
                <>
                    <div className={classes.sectionContainer}>

                        <span className={classes.sectionTitle}>Recent Builds</span>

                        <div className={classes.filter}>
                            <div className={classes.selectWrapper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.select}>
                                    <InputLabel id="branch-label">Filter branch...</InputLabel>
                                    <Select
                                        labelId="branch-label"
                                        label="Filter branch..."
                                        variant="outlined"
                                        value={branch || ""}
                                        onChange={(e => setBranch((e.target.value as string) || null))}
                                        MenuProps={{
                                            classes: {
                                                paper: classes.selectPaper
                                            }
                                        }}>
                                        <MenuItem value="">
                                            <em>
                                                All branches
                                            </em>
                                        </MenuItem>
                                        <MenuItem value="n/a">
                                            <em>
                                                Unknown branch
                                            </em>
                                        </MenuItem>
                                        {branches.map(branch => (
                                            <MenuItem
                                                key={branch}
                                                value={branch}>
                                                {branch}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.selectWrapper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.select}>
                                    <InputLabel id="type-label">Filter build type...</InputLabel>
                                    <Select
                                        labelId="branch-label"
                                        label="Filter build type..."
                                        variant="outlined"
                                        value={type || ""}
                                        onChange={(e => setType((e.target.value as "ci" | "local" | "") || null))}
                                        MenuProps={{
                                            classes: {
                                                paper: classes.selectPaper
                                            }
                                        }}>
                                        <MenuItem value="">
                                            <em>
                                                All build types
                                            </em>
                                        </MenuItem>
                                        <MenuItem value="ci">
                                            CI builds
                                        </MenuItem>
                                        <MenuItem value="local">
                                            Local builds
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        {builds?.sort((a, b) => compareTimestamps(a.createdAt, b.createdAt))
                            .map(build => (
                                <BuildRow
                                    key={build.id}
                                    build={build}
                                    repository={props.repository}/>
                            ))}
                    </div>

                </>
            )}
        </div>
    );
};

export default RepositoryBuilds;