import {IconButton, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Delete, Edit, Person} from "@material-ui/icons";
import React from "react";
import {AssignedRepositoryRO, RepositoryUserRO, UserRO} from "../../../../api/api";

interface Props {
    userInfo: UserRO | undefined;
    disabled: boolean;
    user: RepositoryUserRO;
    onEdit: (user: RepositoryUserRO) => void;
    onDelete: (user: RepositoryUserRO) => void;
    repository: AssignedRepositoryRO | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    userRow: {
        "&:not(:first-child)": {
            borderTop: "1px solid #CCC"
        },
        padding: "0.75rem 1rem",
        display: "flex",
        alignItems: "center"
    },
    user: {
        fontSize: "1rem",
        marginLeft: "1rem"
    },
    userRole: {
        width: "75px",
        textAlign: "center",
        margin: "auto 1rem auto auto",
        fontSize: "0.9rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0.25rem",
        padding: "0.15rem 0.5rem",
        color: theme.palette.primary.contrastText,
        textTransform: "capitalize"
    }
}));

const MemberRow: React.FC<Props> = props => {
    const classes = useStyles();

    const isSelf = props.user.email === props.userInfo?.email;
    const isOwner = props.user.role === "OWNER";
    const isAdminAndCannotBeRemoved = props.user.role === "ADMIN" && props.repository?.role !== "OWNER";
    const isOwnerAndCannotBeRemoved = props.user.role === "OWNER" && props.repository?.role !== "OWNER";
    const isDeleteDisabled = props.disabled || isSelf || isOwner || isAdminAndCannotBeRemoved;
    const isEditDisabled = props.disabled || isSelf || isOwnerAndCannotBeRemoved || isAdminAndCannotBeRemoved;

    return (
        <div
            key={props.user.email}
            className={classes.userRow}>

            <Person fontSize="small"/>

            <span className={classes.user}>
                {props.user.email}
            </span>

            <span className={classes.userRole}>
                {props.user.role.toLowerCase()}
            </span>

            <IconButton
                size="small"
                disabled={isEditDisabled}
                onClick={() => props.onEdit(props.user)}>
                <Edit fontSize="small"/>
            </IconButton>

            <IconButton
                size="small"
                disabled={isDeleteDisabled}
                onClick={() => props.onDelete(props.user)}>
                <Delete fontSize="small"/>
            </IconButton>

        </div>
    );
};

export default MemberRow;