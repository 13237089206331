import {RepositoryAPI} from "../api/api";
import createListApiState from "../state-test/ListApiState";

const [repositoriesSlice, loadRepositories, updateRepositoriesState] = createListApiState({
    idKey: "id",
    execute: RepositoryAPI.loadRepositories,
    cacheTimeout: 60,
    name: "Repositories"
});

export {
    repositoriesSlice, loadRepositories, updateRepositoriesState
};