import {makeStyles, Theme} from "@material-ui/core/styles";
import {default as React} from "react";
import BetaBadge from "./BetaBadge";
import MenuLogo from "./MenuLogo";
import UserButton from "./UserButton";

const useStyles = makeStyles((theme: Theme) => ({
    menuContainer: {
        backgroundColor: theme.palette.primary.main,
        height: "75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    menu: {
        height: "100%",
        flexGrow: 1,
        display: "flex",
        maxWidth: "960px"
    },
    spacer: {
        flexGrow: 1
    }
}));

const Menu: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.menuContainer}>

            <div className={classes.menu}>

                <MenuLogo/>

                <BetaBadge/>

                <span className={classes.spacer}/>

                <UserButton/>

            </div>

        </div>
    );
}

export default Menu;