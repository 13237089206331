import {Collapse, IconButton} from "@material-ui/core";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Refresh} from "@material-ui/icons";
import {Alert, Color} from "@material-ui/lab";
import clsx from "clsx";
import React from "react";

export interface ToastAction {
    icon: React.ElementType;
    onClick: () => void;
}

interface Props {
    message: string | undefined;
    severity?: Color;
    className?: string;
    onClose?: () => void;
    alertClassName?: string;
    action?: ToastAction;
}

const useStyles = makeStyles((theme: Theme) => ({
    actionButton: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    actionButtonIcon: {
        color: theme.palette.primary.contrastText,
        marginRight: "0.5rem"
    },
    alert: {
        marginTop: "1rem",
        marginBottom: "1rem"
    }
}));

export const Toast: React.FC<Props> = props => {
    const classes = useStyles();

    if (props.action && props.onClose) {
        throw new Error("Only action or onClose must be passed!");
    }

    return (
        <Collapse
            in={!!props.message}
            className={props.className}>

            <Alert
                variant="filled"
                onClose={props.onClose}
                severity={props.severity || "error"}
                className={clsx(classes.alert, props.alertClassName)}
                action={props.action && (

                    <IconButton
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        className={classes.actionButton}
                        size="small"
                        onClick={props.action.onClick}>

                        {React.createElement(props.action.icon, {
                            fontSize: "small",
                            className: classes.actionButtonIcon
                        })}

                    </IconButton>

                )}>

                {props.message}

            </Alert>

        </Collapse>
    );
};

export const retryAction = (onRetry: () => void): ToastAction => {
    return {
        onClick: onRetry,
        icon: Refresh
    };
};