import {makeStyles, Theme} from "@material-ui/core/styles";
import Viewer from "bpmn-js/lib/NavigatedViewer";
import clsx from "clsx";
import $ from "jquery";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FlowNodeRO, SequenceFlowRO} from "../../api/api";

export declare type PlayerListener = {
    dispatch: (event: PlayerEvent) => void;
};
export declare type PlayerEvent =
    | "ZOOM_IN"
    | "ZOOM_OUT"
    | "RESET_ZOOM"
    | "SPEED_SLOW"
    | "SPEED_MEDIUM"
    | "SPEED_FAST"
    | "BACK_START"
    | "BACK_STEP"
    | "PLAY"
    | "PAUSE"
    | "NEXT_STEP"
    | "NEXT_END";

export declare type ExternalPlayerListener = (event: ExternalPlayerEvent) => void;
export declare type ExternalPlayerEvent =
    | "DISABLE_BACK_START"
    | "ENABLE_BACK_START"
    | "DISABLE_BACK_STEP"
    | "ENABLE_BACK_STEP"
    | "PLAY_STARTED"
    | "PLAY_PAUSED"
    | "DISABLE_PLAY"
    | "ENABLE_PLAY"
    | "DISABLE_NEXT_STEP"
    | "ENABLE_NEXT_STEP"
    | "DISABLE_NEXT_END"
    | "ENABLE_NEXT_END";

export declare type PlayerSpeed = "slow" | "medium" | "fast";

declare type EndStepActionType = "NODE_PARTIAL";

declare type DefaultStepActionType =
    | "NODE_STARTED"
    | "NODE_ENDED"
    | "FLOW_STARTED";

interface DefaultStepAction {
    type: DefaultStepActionType;
    executionCount: number;
    targetElementType: string;
    targetElement: string;
}

interface EndStepAction {
    type: EndStepActionType;
}

declare type StepAction = DefaultStepAction | EndStepAction;

interface Step {
    actions: StepAction[];
}

interface Props {
    xml: string;
    className?: string;
    setEventDispatcher: (listener: PlayerListener) => void;
    onEvent: ExternalPlayerListener;
    flows: SequenceFlowRO[];
    nodes: FlowNodeRO[];
}

// TODO: Safari has bugs when displaying coverage player
const isSafari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

const ALL_NODE_SELECTOR = "#bpmn-player-canvas g.djs-element.djs-shape > .djs-visual > :nth-child(1)";
const ALL_FLOW_SELECTOR = "#bpmn-player-canvas g.djs-element.djs-connection path";

const getNodeClassSelector = (className: string) => "#bpmn-player-canvas g[data-element-id] > .djs-visual > ." + className + ":nth-child(1)";
const getNodeSelector = (id: string) => "#bpmn-player-canvas g[data-element-id='" + id + "'] > .djs-visual > :nth-child(1)";
const getFlowSelector = (id: string) => "#bpmn-player-canvas g[data-element-id='" + id + "'] path";

const SPEEDS = {
    fast: 500,
    medium: 1000,
    slow: 1500
};

interface MergedEndStep {
    type: EndStepActionType,
    index: number
}

interface MergedDefaultStep {
    type: DefaultStepActionType,
    targetElementType: string,
    executionCount: number,
    index: number,
    data: SequenceFlowRO | FlowNodeRO
}

declare type MergedStep = MergedDefaultStep | MergedEndStep;

const calculateSteps = (flows: SequenceFlowRO[], nodes: FlowNodeRO[]): Step[] => {
    const merged: MergedStep[] = [];

    const passedNodes: Map<string, number> = new Map();
    const passedFlows: Map<string, number> = new Map();

    let partialFound = false;

    // Merge flows
    flows.forEach(flow => {
        const count = (passedFlows.get(flow.key) || 0) + 1;
        // Don't add overlays for sequence flows
        if (count === 1) {
            merged.push({
                type: "FLOW_STARTED",
                targetElementType: "sequenceFlow",
                executionCount: count,
                index: flow.executionStartCounter,
                data: flow
            });
            passedFlows.set(flow.key, count);
        }
    });

    // Merge nodes
    nodes.forEach(node => {
        const count = (passedNodes.get(node.key) || 0) + 1;
        if (count > 1) {
            merged.push({
                type: "NODE_ENDED",
                targetElementType: node.type,
                executionCount: count,
                index: node.executionStartCounter,
                data: node
            });
        } else {
            if (node.executionEndCounter === null) {
                partialFound = true;
                merged.push({
                    type: "NODE_STARTED",
                    targetElementType: node.type,
                    executionCount: count,
                    index: node.executionStartCounter,
                    data: node
                });
            } else {
                if (node.executionEndCounter - node.executionStartCounter > 1) {
                    merged.push({
                        type: "NODE_STARTED",
                        targetElementType: node.type,
                        executionCount: count,
                        index: node.executionStartCounter,
                        data: node
                    });
                }

                merged.push({
                    type: "NODE_ENDED",
                    targetElementType: node.type,
                    executionCount: count,
                    index: node.executionEndCounter,
                    data: node
                });
            }
        }
        passedNodes.set(node.key, count);
    });

    if (partialFound) {
        merged.push({
            type: "NODE_PARTIAL",
            index: Number.MAX_SAFE_INTEGER
        });
    }

    // Sort and map to result objects
    return merged
        .sort((a, b) => a.index - b.index)
        .map(element => {
            if (element.type === "NODE_PARTIAL") {
                return {
                    actions: [
                        {
                            type: element.type
                        }
                    ]
                }
            } else {
                return {
                    actions: [
                        {
                            targetElement: element.data.key,
                            targetElementType: element.targetElementType,
                            executionCount: element.executionCount,
                            type: element.type
                        }
                    ]
                };
            }
        });
};

const getColor = (element: DefaultStepAction, classSmall: string, classLarge: string): string => {
    if(element.targetElementType === "subProcess") {
        return classLarge;
    }

    return classSmall;
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        alignItems: "stretch",
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    modeler: {
        height: "100%",
        flexGrow: 1
    },
    flowStarted: {
        stroke: "rgba(20, 125, 20, 1) !important",
        strokeWidth: "3px !important"
    },
    nodeStarted: {
        fill: "rgb(152, 201, 249) !important"
    },
    nodeStartedLarge: {
        fill: "rgb(202, 228, 252) !important"
    },
    nodeEnded: {
        fill: "rgb(168, 231, 161) !important"
    },
    nodeEndedLarge: {
        fill: "rgb(208, 242, 205) !important"
    },
    nodePartial: {
        fill: "rgb(255, 223, 150) !important"
    },
    nodePartialLarge: {
        fill: "rgb(255, 238, 202) !important"
    },
    flowAnimation: {
        transition: theme.transitions.create(["stroke", "stroke-width"])
    },
    nodeAnimation: {
        transition: theme.transitions.create("fill")
    },
    overlay: {
        backgroundColor: "#607D8B",
        borderRadius: "8px",
        padding: "0.25rem 0.5rem",
        color: "white",
        fontWeight: 600
    }
}));

let viewer: Viewer | undefined;
let timeout: any;

const overlays: Map<string, string> = new Map();

const BpmnPlayer: React.FC<Props> = props => {
    const classes = useStyles();

    const {
        xml,
        nodes,
        flows,
        onEvent,
        setEventDispatcher
    } = props;

    const [playing, setPlayingState] = useState(false);
    const [step, setStepState] = useState(0);
    const [speed, setSpeed] = useState<PlayerSpeed>("medium");

    // Calculate steps from nodes and flows
    const steps = useMemo(() => {
        return calculateSteps(flows, nodes);
    }, [flows, nodes]);

    const setPlaying = useCallback((playing: boolean) => {
        setPlayingState(playing);
        if (playing) {
            onEvent("PLAY_STARTED");
        } else {
            onEvent("PLAY_PAUSED");
        }
    }, [onEvent]);

    const createOverlay = useCallback((targetElement: string, count: number, type: "node" | "flow"): string => {
        return viewer?.get("overlays").add(targetElement, {
            position: {
                bottom: type === "node" ? 14 : 32,
                right: type === "node" ? 16 : 52
            },
            html: `<div class=${classes.overlay}>${count}x</div>`
        }) || "";
    }, [classes]);

    const removeOverlay = useCallback((overlayId: string) => {
        viewer?.get("overlays").remove(overlayId);
    }, []);

    const applySteps = useCallback((currentStep: number, newStep: number, undo: boolean) => {
        if (!undo) {
            // Apply steps
            for (let i = currentStep; i < newStep; i++) {
                const actions = steps[i].actions;
                // Apply actions for this step
                actions.forEach(action => {
                    switch (action.type) {

                        case "FLOW_STARTED":
                            if (action.executionCount === 1) {
                                // Sequence flow was passed for the first time, highlight it
                                $(getFlowSelector(action.targetElement)).addClass(classes.flowStarted);
                            } else {
                                // Sequence flow was passed for the second time, add overlay
                                //
                                // Don't add overlays for sequence flows
                                // const overlayId = createOverlay(action.targetElement, action.executionCount, "flow")
                                // overlays.set(action.targetElement + action.executionCount, overlayId);
                            }
                            break;

                        case "NODE_STARTED":
                            // Add class to node
                            $(getNodeSelector(action.targetElement))
                                // Select large or small color depending on element type
                                .addClass(getColor(action, classes.nodeStarted, classes.nodeStartedLarge));
                            break;

                        case "NODE_ENDED":
                            if (action.executionCount === 1) {
                                // Node was passed for the first time, highlight it
                                $(getNodeSelector(action.targetElement))
                                    // Select large or small color depending on element type
                                    .addClass(getColor(action, classes.nodeEnded, classes.nodeEndedLarge));
                            } else {
                                // NOde was passed for the second time, add overlay with count
                                const overlayId = createOverlay(action.targetElement, action.executionCount, "node")
                                overlays.set(action.targetElement + action.executionCount, overlayId);
                            }
                            break;

                        case "NODE_PARTIAL":
                            // Find all nodes that have been started but not ended (e.g. in a previous execution)
                            $(getNodeClassSelector(classes.nodeStarted) + ":not(." + classes.nodeEnded + ")")
                                // and highlight them
                                .addClass(classes.nodePartial);
                            // Same for large elements
                            $(getNodeClassSelector(classes.nodeStartedLarge) + ":not(." + classes.nodeEndedLarge + ")")
                                .addClass(classes.nodePartialLarge);
                            break;
                    }
                });
            }
        } else {
            // Undo steps
            for (let i = currentStep - 1; i >= newStep; i--) {
                const actions = steps[i].actions;
                // Undo actions for this step
                actions.forEach(action => {
                    switch (action.type) {

                        case "FLOW_STARTED":
                            if (action.executionCount === 1) {
                                // Sequence flow was passed for the first time, remove the highlighting
                                $(getFlowSelector(action.targetElement)).removeClass(classes.flowStarted);
                            } else {
                                // Don't add overlays for sequence flows
                                // const overlayKey = action.targetElement + action.executionCount;
                                // if (overlays.has(overlayKey)) {
                                //     removeOverlay(overlays.get(overlayKey)!);
                                //     overlays.delete(overlayKey);
                                // }
                            }
                            break;

                        case "NODE_STARTED":
                            // Remove the highlighting
                            $(getNodeSelector(action.targetElement))
                                // Select large or small color depending on element type
                                .removeClass(getColor(action, classes.nodeStarted, classes.nodeStartedLarge));
                            break;

                        case "NODE_ENDED":
                            if (action.executionCount === 1) {
                                // Node was passed for the first time, remove the highlighting
                                $(getNodeSelector(action.targetElement))
                                    // Select large or small color depending on element type
                                    .removeClass(getColor(action, classes.nodeEnded, classes.nodeEndedLarge));
                            } else {
                                // Node was pssed for the second time, remove the overlay with the count
                                const overlayKey = action.targetElement + action.executionCount;
                                if (overlays.has(overlayKey)) {
                                    removeOverlay(overlays.get(overlayKey)!);
                                    overlays.delete(overlayKey);
                                }
                            }
                            break;

                        case "NODE_PARTIAL":
                            // Find all nodes that have been started but not ended (e.g. in a previous execution)
                            $(getNodeClassSelector(classes.nodeStarted) + ":not(." + classes.nodeEnded + ")")
                                // and remove the highlighting
                                .removeClass(classes.nodePartial);
                            // Same for large elements
                            $(getNodeClassSelector(classes.nodeStartedLarge) + ":not(." + classes.nodeEndedLarge + ")")
                                .removeClass(classes.nodePartialLarge);
                            break;
                    }
                });
            }
        }
    }, [steps, classes, createOverlay, removeOverlay]);

    const setStep = useCallback((newStep: number) => {
        if (step === newStep) {
            return;
        }

        applySteps(step, newStep, newStep < step);

        setStepState(newStep);
        onEvent(newStep === 0 ? "DISABLE_BACK_START" : "ENABLE_BACK_START");
        onEvent(newStep === 0 ? "DISABLE_BACK_STEP" : "ENABLE_BACK_STEP");
        onEvent(newStep === steps.length ? "DISABLE_PLAY" : "ENABLE_PLAY");
        onEvent(newStep === steps.length ? "DISABLE_NEXT_STEP" : "ENABLE_NEXT_STEP");
        onEvent(newStep === steps.length ? "DISABLE_NEXT_END" : "ENABLE_NEXT_END");
    }, [step, steps, applySteps, onEvent]);

    const listener = useMemo(() => ({
        dispatch: (event: PlayerEvent) => {
            switch (event) {
                case "BACK_START":
                    setPlaying(false);
                    setStep(0);
                    break;
                case "BACK_STEP":
                    setPlaying(false);
                    setStep(Math.max(0, step - 1));
                    break;
                case "NEXT_END":
                    setPlaying(false);
                    setStep(steps.length);
                    break;
                case "NEXT_STEP":
                    setPlaying(false);
                    setStep(Math.min(steps.length, step + 1));
                    break;
                case "PAUSE":
                    setPlaying(false);
                    break;
                case "PLAY":
                    setPlaying(true);
                    break;
                case "SPEED_SLOW":
                    setSpeed("slow");
                    break;
                case "SPEED_MEDIUM":
                    setSpeed("medium");
                    break;
                case "SPEED_FAST":
                    setSpeed("fast");
                    break;
                case "RESET_ZOOM":
                    if (!isSafari) {
                        viewer?.get("canvas").zoom('fit-viewport', true);
                    }
                    break;
                case "ZOOM_IN":
                    viewer?.get("zoomScroll").zoom(1);
                    break;
                case "ZOOM_OUT":
                    viewer?.get("zoomScroll").zoom(-1);
                    break;
            }
        }
    }), [setPlaying, setStep, step, steps.length]);

    // Update step every interval if playing and not last step reached
    useEffect(() => {
        if (playing && step < steps.length) {
            timeout = setTimeout(() => setStep(step + 1), SPEEDS[speed]);
        } else if (step === steps.length) {
            setPlaying(false);
        }
    }, [playing, step, setStep, steps.length, setPlaying, speed]);

    // Clear timeout on pause
    useEffect(() => {
        if (timeout && !playing) {
            clearTimeout(timeout);
        }
    }, [playing]);

    // Propagate listener to parent
    useEffect(() => {
        if (setEventDispatcher) {
            setEventDispatcher(listener);
        }
    }, [setEventDispatcher, listener]);

    // Create viewer once
    useEffect(() => {
        viewer = new Viewer({
            container: "#bpmn-player-canvas"
        });
        viewer.get("zoomScroll").toggle(false);
    }, []);

    // Import XML whenever it changes
    useEffect(() => {
        (async () => {
            if (xml && viewer) {
                // Import XML
                await viewer.importXML(xml);

                // Clear all overlays
                overlays.clear();

                if (!isSafari) {
                    // Zoom to fit viewport
                    const canvas = viewer.get("canvas");
                    canvas.zoom('fit-viewport', true);
                }

                // Apply animation classes
                $(ALL_NODE_SELECTOR).addClass(classes.nodeAnimation);
                $(ALL_FLOW_SELECTOR).addClass(classes.flowAnimation);
            }
        })();
    }, [xml, classes]);

    return (
        <div className={clsx(classes.root, props.className)}>
            <div className={classes.modeler} id="bpmn-player-canvas"/>
        </div>
    );
}

export default BpmnPlayer;