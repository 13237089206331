import {makeStyles, Theme} from "@material-ui/core/styles";
import {default as React} from "react";
import {Link} from "react-router-dom";
import logo from "../../../img/logo.svg";

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        textDecoration: "none",
        display: "flex"
    },
    logo: {
        width: "200px"
    }
}));

const MenuLogo: React.FC = () => {
    const classes = useStyles();

    return (
        <Link
            className={classes.link}
            to="/">
            <img
                className={classes.logo}
                src={logo}
                alt=""/>
        </Link>
    );
}

export default MenuLogo;