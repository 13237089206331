import {makeStyles} from "@material-ui/core/styles";
import {default as React} from "react";

const useStyles = makeStyles(() => ({
    sectionDescription: {
        fontSize: "0.9rem",
        marginBottom: "1rem"
    }
}));

const SectionDescription: React.FC = props => {
    const classes = useStyles();

    return (
        <span className={classes.sectionDescription}>
            {props.children}
        </span>
    );
}

export default SectionDescription;