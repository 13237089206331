import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";

interface Props {
    href: string;
    title: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    bottomLink: {
        textDecoration: "none",
        color: "white",
        fontSize: "0.9rem",
        margin: "0 0.25rem",
        transition: theme.transitions.create("color"),
        "&:hover": {
            color: theme.palette.primary.light
        }
    }
}));

const FooterBottomLink: React.FC<Props> = props => {
    const classes = useStyles();

    return (
        <a
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.bottomLink}>
            <Typography className={classes.bottomLink}>
                {props.title}
            </Typography>
        </a>
    );
};

export default FooterBottomLink;
