import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {
    BpmnModelRO,
    BpmnTestClassRO,
    BpmnTestMethodRO,
    BuildRO,
    DmnModelRO,
    DmnTestClassRO,
    DmnTestMethodRO
} from "../../api/api";
import Section from "../Layout/PageLayout/Section";
import {Tooltip} from "../Util/Tooltip";
import BuildSummaryRow from "./BuildSummaryRow";

interface Props {
    build?: BuildRO;
    selectedModel?: BpmnModelRO | DmnModelRO;
    selectedClass?: BpmnTestClassRO | DmnTestClassRO;
    selectedMethod?: BpmnTestMethodRO | DmnTestMethodRO;
    onSelectionChanged: (
        type: "bpmn" | "dmn",
        selectedModel: BpmnModelRO | DmnModelRO,
        selectedClass?: BpmnTestClassRO | DmnTestClassRO,
        selectedMethod?: BpmnTestMethodRO | DmnTestMethodRO
    ) => void;
    onPlayClicked: (
        selectedModel: BpmnModelRO,
        selectedClass: BpmnTestClassRO,
        selectedMethod: BpmnTestMethodRO
    ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    summaryTable: {
        borderSpacing: "0px !important"
    },
    summaryHead: {
        backgroundColor: "rgba(34, 36, 38, 0.05)",
        "&>th": {
            paddingTop: "1rem",
            paddingBottom: "1rem"
        },
        "&>th:first-child": {
            paddingLeft: "1rem"
        },
        "&>th:last-child": {
            paddingRight: "1rem"
        }
    },
    summaryRow: {
        cursor: "pointer",
        transition: theme.transitions.create("background-color"),
        "&:hover": {
            backgroundColor: "rgba(34, 36, 38, 0.1)"
        },
        "&>td": {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem"
        },
        "&>td:first-child": {
            paddingLeft: "1rem"
        },
        "&>td:last-child": {
            paddingRight: "1rem"
        }
    },
    summaryRowActive: {
        backgroundColor: "rgba(34, 36, 38, 0.15)",
        "&>td": {
            fontWeight: 500
        },
        "&:hover": {
            backgroundColor: "rgba(34, 36, 38, 0.15)"
        }
    },
    summaryFoot: {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        fontWeight: 500,
        "&>td": {
            paddingTop: "1rem",
            paddingBottom: "1rem"
        },
        "&>td:first-child": {
            paddingLeft: "1rem"
        },
        "&>td:last-child": {
            paddingRight: "1rem"
        }
    },
    summaryColumnName: {
        textAlign: "left",
        display: "flex"
    },
    summaryColumnCount: {
        textAlign: "right"
    },
    summaryColumnCoverage: {
        textAlign: "right",
        width: "1%",
        paddingLeft: "1rem"
    },
    separator: {
        margin: "0 0.25rem"
    },
    rowIcon: {
        marginRight: "0.5rem"
    },
    spacingRow: {
        "&>td": {
            padding: "0.25rem"
        }
    },
    summaryColumnNamePadding1: {
        paddingLeft: "2.75rem !important"
    },
    summaryColumnNamePadding2: {
        paddingLeft: "4.5rem !important"
    },
    coverageGreen: {
        backgroundColor: "rgba(0, 255, 0, 0.38)"
    },
    coverageYellow: {
        backgroundColor: "rgba(255, 255, 0, 0.38)"
    },
    coverageRed: {
        backgroundColor: "rgba(255, 0, 0, 0.38)"
    },
    spacingColumn: {
        width: "3rem"
    },
    empty: {
        padding: "1rem",
        textAlign: "center",
        fontWeight: "bold"
    }
}));

const BuildSummary: React.FC<Props> = props => {
    const classes = useStyles();

    if (!props.build) {
        return null;
    }

    return (
        <Section title="Build Summary">
            <table className={classes.summaryTable}>

                <thead>
                <tr className={classes.summaryHead}>
                    <th className={classes.summaryColumnName}>
                        Model
                        <span className={classes.separator}> &gt; </span>
                        Class
                        <span className={classes.separator}> &gt; </span>
                        Method
                    </th>
                    <th className={classes.summaryColumnCount}>
                        <Tooltip title="Covered Flow Nodes, Sequence Flows, and Rules">
                            <span>Covered</span>
                        </Tooltip>
                    </th>
                    <th className={classes.summaryColumnCount}>
                        <Tooltip title="Total Flow Nodes, Sequence Flows, and Rules">
                            <span>Total</span>
                        </Tooltip>
                    </th>
                    <th className={classes.spacingColumn}/>
                    <th className={classes.summaryColumnCoverage}>Coverage</th>
                    <th />
                </tr>
                </thead>

                <tbody>

                <tr className={classes.spacingRow} key="spacing1">
                    <td/>
                </tr>

                {props.build.bpmnModels.length === 0 && props.build.dmnModels.length === 0 && (
                    <tr>
                        <td
                            className={classes.empty}
                            colSpan={99}>
                            This build contains no coverage reports.
                        </td>
                    </tr>
                )}

                {props.build.bpmnModels.map(model => (
                    <>
                        <BuildSummaryRow
                            key={model.processDefinitionKey}
                            type="bpmnModel"
                            model={model}
                            selectedModel={props.selectedModel}
                            selectedClass={props.selectedClass}
                            selectedMethod={props.selectedMethod}
                            onClick={() => props.onSelectionChanged("bpmn", model, undefined, undefined)}/>

                        {props.selectedModel === model && model.classes.map(testClass => (
                            <>
                                <BuildSummaryRow
                                    key={testClass.name}
                                    type="bpmnClass"
                                    model={testClass}
                                    selectedModel={props.selectedModel}
                                    selectedClass={props.selectedClass}
                                    selectedMethod={props.selectedMethod}
                                    onClick={() => props.onSelectionChanged("bpmn", model, testClass, undefined)}/>

                                {props.selectedClass === testClass && testClass.methods.map(method => (
                                    <BuildSummaryRow
                                        key={method.name}
                                        type="bpmnMethod"
                                        model={method}
                                        selectedModel={props.selectedModel}
                                        selectedClass={props.selectedClass}
                                        selectedMethod={props.selectedMethod}
                                        onPlayClicked={() => props.onPlayClicked(model, testClass, method)}
                                        onClick={() => props.onSelectionChanged("bpmn", model, testClass, method)}/>
                                ))}
                            </>
                        ))}
                    </>
                ))}

                {props.build.dmnModels.map(model => (
                    <>
                        <BuildSummaryRow
                            key={model.decisionKey}
                            type="dmnModel"
                            model={model}
                            selectedModel={props.selectedModel}
                            selectedClass={props.selectedClass}
                            selectedMethod={props.selectedMethod}
                            onClick={() => props.onSelectionChanged("dmn", model, undefined, undefined)}/>

                        {props.selectedModel === model && model.classes.map(testClass => (
                            <>
                                <BuildSummaryRow
                                    key={testClass.name}
                                    type="dmnClass"
                                    model={testClass}
                                    selectedModel={props.selectedModel}
                                    selectedClass={props.selectedClass}
                                    selectedMethod={props.selectedMethod}
                                    onClick={() => props.onSelectionChanged("dmn", model, testClass, undefined)}/>

                                {props.selectedClass === testClass && testClass.methods.map(method => (
                                    <BuildSummaryRow
                                        key={method.name}
                                        type="dmnMethod"
                                        model={method}
                                        selectedModel={props.selectedModel}
                                        selectedClass={props.selectedClass}
                                        selectedMethod={props.selectedMethod}
                                        onClick={() => props.onSelectionChanged("dmn", model, testClass, method)}/>
                                ))}
                            </>
                        ))}
                    </>
                ))}

                <tr className={classes.spacingRow} key="spacing2">
                    <td/>
                </tr>
                </tbody>

                <tfoot>
                <tr className={classes.summaryFoot}>
                    <td className={classes.summaryColumnName}>Total Build</td>
                    <td className={classes.summaryColumnCount}>{props.build.coveredFlowNodeCount + props.build.coveredDmnRules + props.build.coveredSequenceFlowCount}</td>
                    <td className={classes.summaryColumnCount}>{props.build.totalNodeCount + props.build.totalRuleCount}</td>
                    <td className={classes.spacingColumn}/>
                    <td className={classes.summaryColumnCoverage}>{(props.build.averageCoverage * 100).toFixed(2)}%</td>
                    <td />
                </tr>
                </tfoot>
            </table>
        </Section>
    );
};

export default BuildSummary;