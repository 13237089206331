import {hasFailed} from "@flowsquad/react-utils-api";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {AssignedRepositoryRO, RepositoryAPI} from "../../../api/api";
import {Toast} from "../../../components/Form/Toast";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";
import {loadRepositories} from "../../../state/RepositoriesState";
import {RootState} from "../../../state/Store";

interface Props {
    repository: AssignedRepositoryRO | undefined;
}

const useStyles = makeStyles(() => ({
    linkButton: {
        textTransform: "none",
        backgroundColor: "red"
    }
}));

const LeaveRepository: React.FC<Props> = props => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const {repository} = props;

    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState<string | undefined>(undefined);

    const userInfoSlice = useSelector((state: RootState) => state.userInfo);

    const loadMembers = useCallback(async () => {
        const email = userInfoSlice.value?.email;

        if (!repository || !email) {
            // Will be executed again when repositoryId or userInfoSlice.value changes
            return;
        }

        if (repository.role === "VIEWER" || repository.role === "MEMBER") {
            setDisabled(false);
            return;
        }

        setError(undefined);
        const result = await RepositoryAPI.loadRepositoryUsers(repository.id);

        if (hasFailed(result)) {
            setError("Could not load remaining repository members: " + result.error + " Reload the page to try again.");
        } else {
            const remainingOwnersCount = result.result
                .filter(user => user.email !== email)
                .filter(user => user.role === "OWNER")
                .length;
            setDisabled(remainingOwnersCount === 0);
        }
    }, [repository, userInfoSlice.value]);

    const onLeaveClicked = useCallback(async () => {
        if (!repository?.id) {
            setError("Repository ID not set. Please wait and try again.");
            return;
        }

        if (!window.confirm("Are you sure you want to leave this repository? You will not be able to join it again on your own!")) {
            return;
        }

        setError(undefined);
        setDisabled(true);

        const result = await RepositoryAPI.leaveRepository(repository.id);
        setDisabled(false);

        if (hasFailed(result)) {
            setError("Could not leave repository: " + result.error);
        } else {
            await dispatch(loadRepositories(true));
            history.push("/");
        }
    }, [repository, history, dispatch]);

    useEffect(() => {
        loadMembers();
    }, [loadMembers]);

    return (
        <Section title="Leave repository">

            <SectionDescription>
                You can leave this repository by clicking the button below. You will lose any access you currently have,
                including the ability to view the repository or upload builds. You can only leave a repository if there
                will be at least one user with the Owner role left.
            </SectionDescription>

            <Toast severity="error" message={error}/>

            <Button
                className={classes.linkButton}
                disableElevation
                color="secondary"
                disabled={disabled}
                variant="contained"
                onClick={onLeaveClicked}
                size="small">
                Leave repository
            </Button>

        </Section>
    );
};

export default LeaveRepository;