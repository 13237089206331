import {Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {Link} from "react-router-dom";

interface Props {
    openNewTab?: boolean;
    external?: boolean;
    title: string;
    href?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        textDecoration: "none",
        color: "white",
        fontSize: "1rem",
        lineHeight: "1.5rem",
        transition: theme.transitions.create(["color", "font-size", "text-align"]),
        "&:hover": {
            color: theme.palette.primary.light
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem"
        },
        [theme.breakpoints.only("xs")]: {
            textAlign: "center"
        }
    },
    info: {
        fontWeight: 400,
        textDecoration: "none",
        color: "white",
        fontSize: "1rem",
        transition: theme.transitions.create(["text-align", "font-size"]),
        [theme.breakpoints.only("xs")]: {
            textAlign: "center"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.9rem"
        }
    },
}));

const FooterLine: React.FC<Props> = props => {
    const classes = useStyles();

    if (!props.href) {
        return (
            <Typography className={classes.info}>
                {props.title}
            </Typography>
        )
    }

    if (props.external) {
        return (
            <a href={props.href}
               className={classes.link}
               target={props.openNewTab ? "_blank" : undefined}
               rel={props.openNewTab ? "noopener noreferrer" : undefined}>

                <Typography className={classes.link}>
                    {props.title}
                </Typography>

            </a>
        );
    }

    return (
        <Link
            to={props.href}
            className={classes.link}>
            <Typography className={classes.link}>
                {props.title}
            </Typography>
        </Link>
    );
};

export default FooterLine;
