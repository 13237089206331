import {makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import Viewer, {DmnView, ViewsChangedEvent} from "dmn-js/lib/NavigatedViewer";
import $ from "jquery";
import React, {useEffect, useState} from "react";

export interface DmnViewerData {
    highlightRules: string[];
    selectedRule: string;
    xml: string;
}

interface Props {
    showCoverage: boolean;
    data?: DmnViewerData;
    className?: string;
    render: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "640px",
        overflow: "hidden"
    },
    modeler: {
        height: "640px"
    },
    highlight: {
        backgroundColor: "rgba(50, 205, 50, 0.50) !important"
    }
}));

let viewer: Viewer | undefined;

const DmnViewer: React.FC<Props> = props => {
    const classes = useStyles();

    const {data, render} = props;

    const [views, setViews] = useState<DmnView[]>([]);

    useEffect(() => {
        viewer = new Viewer({
            decisionTable: {
                additionalModules: [
                    {
                        viewDrd: ['value', null]
                    }
                ]
            },
            container: "#dmn-canvas"
        });
    }, []);

    // Update available views
    useEffect(() => {
        viewer?.on("views.changed", (event: ViewsChangedEvent) => {
            setViews(event.views);
        });
    }, []);

    useEffect(() => {
        const view = views.find(view => view.id === data?.selectedRule);
        if (view) {
            viewer?.open(view);
        }
    }, [views, data]);

    useEffect(() => {
        (async () => {
            if (viewer && data && render) {
                await viewer.importXML(data.xml);
            }
        })();
    }, [data, classes, render]);

    useEffect(() => {
        $("." + classes.highlight)
            .removeClass(classes.highlight);

        if (props.showCoverage && data) {
            for (const row of data.highlightRules) {
                $("td[data-row-id='" + row + "']")
                    .first()
                    .parent()
                    .addClass(classes.highlight);
            }
        }
    });

    return (
        <div className={clsx(classes.root, props.className)}>
            <div className={classes.modeler} id="dmn-canvas"/>
        </div>
    );
}

export default DmnViewer;