import {ApiResponse, hasFailed, SuccessfulResponse} from "@flowsquad/react-utils-api";
import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
    createSlice,
    Draft,
    PayloadAction
} from "@reduxjs/toolkit";
import {RootState} from "../state/Store";
import {
    ApiState,
    LoadFailed,
    LoadFailedPayload,
    LoadStarted,
    LoadSuccessfulPayload,
    reduceLoadFailed,
    reduceLoadStarted
} from "./ApiState";

/**
 * Copyright 2020 FlowSquad GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * The representation of a list redux store state. Useful if you load data indexed by an id from an API and want to
 * cache it. You can then trigger reloads or manual updates on it and still use the current value.
 *
 * @template IdKey  The key of type that is used to index the map
 * @template Type   The type of the value contained in this state
 */
export interface ListApiState<IdKey extends keyof Type, Type> extends ApiState {
    /**
     * The actual value of the state. It consists of an id to value mapping and will be updated (i.e. replaced) on
     * each successful request. If a request fails, no changes to this field will be made. If there has been no
     * successful request yet, the value will be undefined.
     */
    readonly value: Type[] | undefined;

    /**
     * The name of the attribute used as key. Can not be changed.
     */
    readonly key: IdKey;
}

/**
 * The representation of the payload of a "load successful" action.
 *
 * @template Type The type of the actual result
 */
export interface ListLoadSuccessfulPayload<Type> extends LoadSuccessfulPayload {
    /**
     * The actual result of the request. Will replace all existing elements.
     */
    value: Type[];
}

/**
 * The representation of the payload of a manually triggered "update state" action.
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
export interface ListUpdateStatePayload<IdKey extends keyof Type, Type> {
    /**
     * The entries to update.
     */
    update?: Type[];

    /**
     * The keys to delete.
     */
    delete?: Type[IdKey][];

    /**
     * The name of the attribute used as key.
     */
    readonly key: IdKey;
}

/**
 * Constants used for creating the action names.
 */
const ListLoadSucceeded = "ListLoadSucceeded";
const ListUpdateState = "ListUpdateState";

/**
 * Create initial state for every list api state.
 *
 * @param idKey The key used to index the elements
 * @param name The globally unique name of this slice
 */
const createInitialListApiState = <IdKey>(idKey: IdKey, name: string) => ({
    loading: false,
    name: name,
    key: idKey,
    value: undefined
});

/**
 * Processes a succeeded request. Sets the loading state to false, saves value, status code, load time and resets the
 * error.
 *
 * @param state The current state
 * @param action The "load succeeded" action
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
const reduceListLoadSucceeded = <IdKey extends keyof Type, Type>(
    state: Draft<ListApiState<IdKey, Type>>,
    action: PayloadAction<ListLoadSuccessfulPayload<Type>>
) => {
    state.loading = false;
    state.statusCode = action.payload.statusCode;
    state.loadTime = new Date().getTime();
    state.error = undefined;
    state.value = action.payload.value as Draft<Type[]>;
};

/**
 * Processes a update state request. Updates or deletes the specified entries.
 *
 * @param state The current state
 * @param action The "update state" action
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
const reduceListUpdateState = <IdKey extends keyof Type, Type>(
    state: Draft<ListApiState<IdKey, Type>>,
    action: PayloadAction<ListUpdateStatePayload<IdKey, Type>>
) => {
    const remove = ([] as Type[IdKey][])
        .concat(action.payload.delete || [])
        .concat(action.payload.update?.map(entry => entry[action.payload.key]) || []);
    state.value = [...(state.value || [])]
        .filter(entry => remove.indexOf((entry as Type)[action.payload.key]) === -1)
        .concat((action.payload.update || []) as Draft<Type>[]);
};

/**
 * Tries to find the list state of the slice with the specified name from the root state.
 *
 * @param state The root state
 * @param sliceName The name of the slice to find
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
const getListSliceState = <IdKey extends keyof Type, Type>(
    state: RootState,
    sliceName: string
): ListApiState<IdKey, Type> | undefined => {
    return Object.values(state)
        .find(slice => slice?.name === sliceName) as ListApiState<IdKey, Type> | undefined;
}

/**
 * The base options for each createListApiState request.
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
declare type ListBaseOptions<IdKey extends keyof Type, Type> = {
    name: string,
    idKey: IdKey,
    cacheTimeout: number
};

/**
 * The options for a createListApiState request that does not use a custom response.
 *
 * @template IdKey  The key of the type that is used to index the map
 * @template Type   The type of the actual result
 */
declare type ListDefaultOptions<IdKey extends keyof Type, Type> = ListBaseOptions<IdKey, Type> & {
    execute: () => Promise<ApiResponse<Type[]>>
};

/**
 * The options for a createListApiState request that does use a custom response.
 *
 * @template IdKey      The key of the type that is used to index the map
 * @template Type       The type of the actual result
 * @template Response   The type of the api response
 */
declare type ListCustomOptions<IdKey extends keyof Type, Type, Response> = ListBaseOptions<IdKey, Type> & {
    execute: () => Promise<ApiResponse<Response>>,
    transform: (response: Response) => Type[]
};

/**
 * Used as options for the createListApiState method.
 */
declare type ListOptions<IdKey extends keyof Type, Type, Response> =
    | ListDefaultOptions<IdKey, Type>
    | ListCustomOptions<IdKey, Type, Response>;

/**
 * Type assertion to check if the options object uses a custom response transformator.
 *
 * @param param The options object to check
 */
const isCustomListResponse = <IdKey extends keyof Type, Type, Response>(
    param: ListOptions<IdKey, Type, Response>
): param is ListCustomOptions<IdKey, Type, Response> => {
    return !!(param as ListCustomOptions<IdKey, Type, Response>).transform;
};

/**
 * Creates a new list api state for usage with redux toolkit. Returns the slice and the action to start loading the
 * content. All requests must return a list of entities, each containing an unique id. The id is specified via the
 * idKey parameter and will automatically be extracted, unless you specify your own transform function.
 *
 * @template IdKey The type of the id in this state
 * @template Type The type of the value of the state
 * @template Response The type of the response of the api, defaults to Type[]
 *
 * @param options An options object with the following properties: {
 *     name:            The name of the state, must be unique
 *     idKey:           The key of the id attribute
 *     cacheTimeout:    The duration for which only requests with a forceLoad flag are actually executed after a
 *                      successful load
 *     execute:         A function to actually call the API. Should return either an array of Types or an instance of
 *                      Response
 *     transform:       A function to transform the api response manually instead of using the default transform
 *                      function. Required if execute returns an instance of Response.
 * }
 *
 * @return The following items are contained in the returned array:
 *         slice: The state slice
 *         load: The action to trigger a load action
 *         updateState: The action to trigger a manual state update
 */
const createListApiState = <IdKey extends keyof Type, Type, Response = Type[]>(options: ListOptions<IdKey, Type, Response>) => {
    const loadStartedAction = options.name + LoadStarted;
    const loadFailedAction = options.name + LoadFailed;
    const loadSucceededAction = options.name + ListLoadSucceeded;
    const updateStateAction = options.name + ListUpdateState;

    const slice = createSlice({
        name: options.name,
        initialState: createInitialListApiState(options.idKey, options.name) as ListApiState<IdKey, Type>,
        reducers: {
            [loadStartedAction]: reduceLoadStarted,
            [loadFailedAction]: reduceLoadFailed,
            [loadSucceededAction]: reduceListLoadSucceeded,
            [updateStateAction]: reduceListUpdateState
        }
    });

    const loadStarted = slice.actions[loadStartedAction] as unknown as ActionCreatorWithoutPayload; // TODO Why does this not work?
    const loadFailed = slice.actions[loadFailedAction] as ActionCreatorWithPayload<LoadFailedPayload>;
    const loadSucceeded = slice.actions[loadSucceededAction] as ActionCreatorWithPayload<ListLoadSuccessfulPayload<Type>>;
    const updateState = slice.actions[updateStateAction] as unknown as ActionCreatorWithPayload<ListUpdateStatePayload<IdKey, Type>>;

    const load = (forceLoad: boolean = false) => async (dispatch: (arg: any) => void, getState: () => RootState) => {
        // Slice _must_ exist, as long as it is added to the store in Store.ts
        const state = getListSliceState<IdKey, Type>(getState(), options.name)!;
        if (!forceLoad) {
            const loadTime = state?.loadTime || 0;
            const maxAge = new Date().getTime() - options.cacheTimeout * 1000;
            if (loadTime > maxAge) {
                return;
            }
        }

        dispatch(loadStarted());
        const result = await options.execute();
        if (hasFailed(result)) {
            dispatch(loadFailed({
                error: result.error,
                statusCode: result.status
            }));
        } else {
            let transformed: Type[];
            if (isCustomListResponse(options)) {
                const typedResult = result as SuccessfulResponse<Response>;
                transformed = options.transform(typedResult.result);
            } else {
                const typedResult = result as SuccessfulResponse<Type[]>;
                transformed = typedResult.result;
            }
            dispatch(loadSucceeded({
                value: transformed,
                statusCode: result.status
            }));
        }
    };

    return [slice, load, updateState] as [typeof slice, typeof load, typeof updateState];
};

export default createListApiState;