import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import ResetApiKey from "./UserSettings/ResetApiKey";
import RestoreDeletedRepositories from "./UserSettings/RestoreDeletedRepository";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "4rem",
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto"
    }
}));

const UserSettings: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>

            <ResetApiKey/>

            <RestoreDeletedRepositories/>

        </div>
    );
};

export default UserSettings;