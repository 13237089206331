import {hasFailed} from "@flowsquad/react-utils-api";
import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserAPI} from "../../api/api";
import {Toast} from "../../components/Form/Toast";
import Section from "../../components/Layout/PageLayout/Section";
import SectionCode from "../../components/Layout/PageLayout/SectionCode";
import SectionDescription from "../../components/Layout/PageLayout/SectionDescription";
import {RootState} from "../../state/Store";
import {loadUserInfo} from "../../state/UserInfoState";

const useStyles = makeStyles(() => ({
    linkButton: {
        textTransform: "none",
        backgroundColor: "red"
    },
    code: {
        textAlign: "center",
        fontWeight: "bold"
    }
}));

const ResetApiKey: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(loadUserInfo());
    }, [dispatch]);

    const userInfoState = useSelector((state: RootState) => state.userInfo);

    const onResetApiKeyClicked = useCallback(async () => {
        if (!window.confirm("Are you sure you want to reset your API key? This cannot be undone!")) {
            return;
        }

        setError(undefined);
        setDisabled(true);

        const result = await UserAPI.refreshApiKey();
        setDisabled(false);

        if (hasFailed(result)) {
            setError("Could not reset API key: " + result.error);
        } else {
            await dispatch(loadUserInfo(true));
        }
    }, [dispatch]);

    return (
        <Section title="Reset API Key">

            <Toast severity="error" message={error}/>

            <SectionDescription>
                This is your API key. It is required to authenticate when uploading reports via our upload script.
                It is valid for all repositories you are a member of.
            </SectionDescription>

            <SectionCode className={classes.code}>
                <b>{userInfoState.loading ? "Loading..." : userInfoState.value?.apiKey || userInfoState.error}</b>
            </SectionCode>

            <SectionDescription>
                If you think your API key may be compromised, you can reset it.
                <br />
                <b>
                    Please be aware that your old key will no longer be valid, and you will have to update your CI
                    pipeline parameters for ALL repositories.
                </b>
            </SectionDescription>

            <Button
                className={classes.linkButton}
                disableElevation
                color="secondary"
                disabled={disabled}
                variant="contained"
                onClick={onResetApiKeyClicked}
                size="small">
                Reset API Key
            </Button>

        </Section>
    );
};

export default ResetApiKey;