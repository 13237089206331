import {makeStyles, Theme} from "@material-ui/core/styles";
import {History, Laptop, Memory, Person} from "@material-ui/icons";
import React, {FC} from "react";
import {Link} from "react-router-dom";
import {AssignedRepositoryRO, BuildPreviewRO, BuildRO} from "../../api/api";
import {timeSince} from "../../util/DateUtils";
import CoverageCircle from "../Coverage/CoverageCircle";
import Branch from "../Icons/Branch";
import Commit from "../Icons/Commit";

interface Props {
    build: BuildRO | BuildPreviewRO;
    repository?: AssignedRepositoryRO | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    build: {
        display: "flex",
        padding: "1rem",
        "&:not(:last-child)": {
            borderBottom: "1px solid rgba(34, 36, 38, 0.1)"
        }
    },
    buildDetails: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        maxWidth: "calc(100% - 40px)",
        flexGrow: 1
    },
    buildTitle: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: "1rem",
        marginBottom: "0.25rem",
        fontWeight: 600,
        textDecoration: "none",
        color: "#0366d6"
    },
    buildDetailsLine: {
        display: "flex",
        fontWeight: 300
    },
    buildDetailsLineItem: {
        marginRight: "1.25rem",
        display: "flex",
        alignItems: "center"
    },
    buildDetailsLineItemIconExplicit: {
        fill: "rgba(0,0,0,0.54)",
        height: "16px",
        marginRight: "0.4rem"
    },
    buildDetailsLineItemIcon: {
        color: "rgba(0,0,0,0.54)",
        fontSize: "0.9rem",
        marginRight: "0.4rem"
    }
}));

const BuildRow: FC<Props> = props => {
    const classes = useStyles();

    const build = props.build;
    const commitMessage = build.commitMessage || "No commit message available.";
    const commitAuthor = (build.commitAuthor?.indexOf("<") !== -1
        ? build.commitAuthor?.substring(0, build.commitAuthor?.indexOf("<"))
        : build.commitAuthor)
        || "n/a";
    const commitId = build.commitId?.substr(0, 7) || "n/a";
    const branchName = build.branchName
        ? (build.branchName?.length > 32 ? (build.branchName.substr(0, 29) + "...") : build.branchName)
        : "n/a";

    return (
        <div className={classes.build}>

            <CoverageCircle
                totalCoverage={build.averageCoverage}
                bpmnCoverage={build.bpmnCoverage}
                dmnCoverage={build.dmnCoverage}
                size={40}
                thickness={12}/>

            <div className={classes.buildDetails}>

                {props.repository && (
                    <Link
                        to={"/repository/" + props.repository?.id + "/build/" + build.id}
                        className={classes.buildTitle}
                        title={commitMessage}>
                        {commitMessage}
                    </Link>
                )}

                {!props.repository && (
                    <span
                        className={classes.buildTitle}
                        title={commitMessage}>
                        {commitMessage}
                    </span>
                )}

                <div className={classes.buildDetailsLine}>

                    <span
                        className={classes.buildDetailsLineItem}
                        title={build.commitAuthor}>
                        <Person className={classes.buildDetailsLineItemIcon}/>
                        {commitAuthor}
                    </span>

                    <span
                        title={branchName}
                        className={classes.buildDetailsLineItem}>
                        <Branch className={classes.buildDetailsLineItemIconExplicit}/>
                        {branchName}
                    </span>

                    <span
                        className={classes.buildDetailsLineItem}
                        title={commitId}>
                        <Commit className={classes.buildDetailsLineItemIconExplicit}/>
                        {commitId}
                    </span>

                    <span className={classes.buildDetailsLineItem}>
                        <History className={classes.buildDetailsLineItemIcon}/>
                        Created {timeSince(build.createdAt)} ago
                    </span>

                    {build.ci && (
                        <span className={classes.buildDetailsLineItem}>
                            <Memory className={classes.buildDetailsLineItemIcon}/>
                            CI Build
                        </span>
                    )}

                    {!build.ci && (
                        <span className={classes.buildDetailsLineItem}>
                            <Laptop className={classes.buildDetailsLineItemIcon}/>
                            Local Build
                        </span>
                    )}

                </div>

            </div>

        </div>
    );
};

export default BuildRow;