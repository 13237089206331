import {hasFailed} from "@flowsquad/react-utils-api";
import {Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AssignedRepositoryRO, RepositoryAPI, RepositoryUserRO} from "../../../api/api";
import {retryAction, Toast} from "../../../components/Form/Toast";
import Section from "../../../components/Layout/PageLayout/Section";
import SectionDescription from "../../../components/Layout/PageLayout/SectionDescription";
import SmallButton from "../../../components/Layout/PageLayout/SmallButton";
import {RootState} from "../../../state/Store";
import {UserRole} from "../../../util/RoleUtils";
import EditMemberRow from "./RepositoryMembers/EditMemberRow";
import MemberRow from "./RepositoryMembers/MemberRow";

interface Props {
    repository: AssignedRepositoryRO | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
    userRows: {
        paddingTop: "0.5rem"
    },
    loading: {
        margin: "1rem auto",
        fontWeight: "bold"
    }
}));

const RepositoryMembers: React.FC<Props> = props => {
    const classes = useStyles();

    const {repository} = props;

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [users, setUsers] = useState<RepositoryUserRO[] | undefined>(undefined);
    const [editUser, setEditUser] = useState<RepositoryUserRO | undefined>(undefined);

    const userInfoState = useSelector((state: RootState) => state.userInfo);

    const loadMembers = useCallback(async () => {
        if (!repository) {
            // Will be executed again when repositoryId changes
            return;
        }

        setLoading(true);
        setError(undefined);

        const result = await RepositoryAPI.loadRepositoryUsers(repository.id);
        setLoading(false);

        if (hasFailed(result)) {
            setError("Could not load repository members: " + result.error);
        } else {
            setUsers(result.result);
        }
    }, [repository]);

    const onDelete = useCallback(async (user: RepositoryUserRO) => {
        if (!repository) {
            setError("Repository id not set, please wait and try again.");
            return;
        }

        if (!window.confirm("Are you sure you want to remove " + user.email + " from this repository?")) {
            return;
        }

        setError(undefined);
        setDisabled(true);
        const result = await RepositoryAPI.removeUser({
            email: user.email,
            repositoryId: repository.id
        });

        setDisabled(false);
        if (hasFailed(result)) {
            setError("Could not remove user: " + result.error);
        } else {
            loadMembers();
        }
    }, [repository, loadMembers]);

    const onRoleChanged = useCallback(async (user: RepositoryUserRO, role: UserRole) => {
        if (!repository) {
            setError("Repository id not set, please wait and try again.");
            return;
        }

        setError(undefined);
        setDisabled(true);
        const result = await RepositoryAPI.grantUserRole({
            email: user.email,
            repositoryId: repository.id,
            role: role
        });

        setDisabled(false);
        if (hasFailed(result)) {
            setError("Could not change user's role: " + result.error);
        } else {
            loadMembers();
        }
    }, [repository, loadMembers]);

    useEffect(() => {
        loadMembers();
    }, [loadMembers]);

    return (
        <Section title="Repository members">

            <SectionDescription>
                The following users are members of this repository.
            </SectionDescription>

            <SmallButton
                openNewTab
                link="https://docs.flowcov.io/getting-started/collaborating"
                text="Learn more"/>

            <Toast
                message={error}
                action={retryAction(loadMembers)}/>

            {loading && (
                <span className={classes.loading}>
                    Loading...
                </span>
            )}

            <div className={classes.userRows}>
                {users?.map(user => {
                    if (user === editUser) {
                        return (
                            <EditMemberRow
                                onCancel={() => setEditUser(undefined)}
                                onRoleChanged={onRoleChanged}
                                key={user.email}
                                disabled={disabled}
                                repository={props.repository}
                                user={user}/>
                        )
                    }

                    return (
                        <MemberRow
                            key={user.email}
                            userInfo={userInfoState.value}
                            onEdit={setEditUser}
                            disabled={disabled}
                            onDelete={onDelete}
                            repository={props.repository}
                            user={user}/>
                    );
                })}
            </div>

        </Section>
    );
};

export default RepositoryMembers;