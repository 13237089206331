import {Draft, PayloadAction} from "@reduxjs/toolkit";

/**
 * Copyright 2020 FlowSquad GmbH
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * The base representation of a redux store state. Can be used as base class for any state representations.
 */
export interface ApiState {
    /**
     * The error that occurred during the most recently failed request. Will be reset on the next successful request.
     */
    error?: string;

    /**
     * The current loading state.
     */
    loading: boolean;

    /**
     * The time the most recent successful request completed.
     */
    loadTime?: number;

    /**
     * The status code of the most recently completed request, whether it was successful or failed.
     */
    statusCode?: number;

    /**
     * The globally unique name of this state slice. Can not be changed.
     */
    readonly name: string;
}

/**
 * The representation of the payload of a "load failed" action.
 */
export interface LoadFailedPayload {
    /**
     * The error that occurred.
     */
    error: string;

    /**
     * The status code of the request.
     */
    statusCode: number;
}

/**
 * The base representation of the payload of a "load successful" action. Can be used as a base class for any successful
 * response payload.
 */
export interface LoadSuccessfulPayload {
    /**
     * The status code of the request.
     */
    statusCode: number;
}

/**
 * Constants used for creating the action names.
 */
export const LoadStarted = "LoadStarted";
export const LoadFailed = "LoadFailed";

/**
 * Processes a started request, sets the loading state to true, and clears the error.
 *
 * @param state The current state
 */
export const reduceLoadStarted = (state: Draft<ApiState>) => {
    state.loading = true;
    state.error = undefined;
};

/**
 * Processes a failed request. Sets the loading state to false and saves error message and status code.
 *
 * @param state The current state
 * @param action The "load failed" action
 */
export const reduceLoadFailed = (state: Draft<ApiState>, action: PayloadAction<LoadFailedPayload>) => {
    state.loading = false;
    state.error = action.payload.error;
    state.statusCode = action.payload.statusCode;
};